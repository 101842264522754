import { RouterProvider } from "react-router-dom";

// Core
import { authStore } from "core/authentication/store";

// Router
import { router } from "./router";

// Components
import PageLoading from "pages/utils/loading";
import PageUserNotFound from "pages/utils/user-not-found";

const Dashboard = () => {
    const { isUserLoaded, isUserNotFound } = authStore();

    if (!isUserLoaded) {
        return <PageLoading />;
    }

    if (isUserNotFound) {
        return <PageUserNotFound />;
    }

    return <RouterProvider router={router} />;
};

export default Dashboard;
