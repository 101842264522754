// Libraries
import {
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
} from "firebase/auth";

// Firebase
import { auth } from "core/firebase";

// Actions
import { setUserListener } from "core/user";

export const signInWithEmail = async (email = "", password = "") => {
    const { user, error } = await signInWithEmailAndPassword(
        auth,
        email,
        password
    )
        .then(({ user }) => ({ user }))
        .catch((error) => ({ error }));

    if (error) {
        return error.code;
    }

    setUserListener(user);
};

export const resetWithEmail = async (email) => {
    const error = await sendPasswordResetEmail(auth, email).catch(
        (error) => error
    );

    if (error) {
        return error.code;
    }

    return false;
};
