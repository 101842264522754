import { useState } from "react";

// Icons
import { PlusCircleIcon } from "@heroicons/react/24/outline";

// Core
import { createLocation } from "core/location/actions";
import { notificationStore } from "core/notification/store";

// Components
import Button from "components/button";
import Input from "components/inputs/input";

const Form = ({ search = "", setSearch }) => {
	const [loading, setLoading] = useState(false);

	// Store
	const { openNotification } = notificationStore();

	const onChange = ({ target }) => {
		setSearch(target.value);
	};

	const onSubmitOntmoetingsplek = async () => {
		setLoading(true);

		const { id, error = null } = await createLocation(search);

		console.log(id);

		if (error) {
			openNotification({
				error: true,
				message: "Fout bij het aanmaken van de locatie",
			});
		} else {
			setSearch("");
			openNotification({
				error: false,
				message: "locatie succesvol aangemaakt",
			});
		}

		setLoading(false);
	};

	return (
		<div className="flex mt-4 mb-4">
			<Input
				type="search"
				placeholder="Zoer hier naar een locatie"
				value={search}
				onChange={onChange}
			/>
			<Button
				onClick={onSubmitOntmoetingsplek}
				disabled={!search || !search.length}
				loading={loading}
				text="Toevoegen"
				size="h-10"
				className="ml-4"
			>
				{!loading && (
					<PlusCircleIcon className="h-5 w-5 stroke-2 ml-2" />
				)}
			</Button>
		</div>
	);
};

export default Form;
