import { memo, useState, useEffect } from "react";

// Core
import { collection, query, getDocs, where } from "firebase/firestore";
import { db } from "core/firebase";
import { unwrapList } from "core/utils";

// Libraries
import { useJsApiLoader, GoogleMap } from "@react-google-maps/api";

// Config
import { startPosition } from "./config";

// Components
import Navigation from "components/navigation/map";
import Marker from "components/map/marker";

const Map = () => {
	const [zoom] = useState(12);
	const [center] = useState(startPosition);

	const [locations, setLocations] = useState([]);
	const [residents, setResidents] = useState([]);
	const [activities, setActivities] = useState([]);

	const [activeLocations, setActiveLocations] = useState(true);
	const [activeResidents, setActiveResidents] = useState(true);
	const [activeActivities, setActiveActivities] = useState(true);

	const { isLoaded, loadError } = useJsApiLoader({
		googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
	});

	useEffect(() => {
		const queryLocations = query(
			collection(db, "locations"),
			where("address", "!=", null)
		);

		const quertResidents = query(
			collection(db, "residents"),
			where("address", "!=", null)
		);

		const queryActivities = query(
			collection(db, "activities"),
			where("address", "!=", null)
		);

		getDocs(queryLocations).then((payload) =>
			setLocations(unwrapList(payload))
		);
		getDocs(quertResidents).then((payload) =>
			setResidents(unwrapList(payload))
		);
		getDocs(queryActivities).then((payload) =>
			setActivities(unwrapList(payload))
		);
	}, []);

	const toggleLocations = () => {
		setActiveLocations(!activeLocations);
	};

	const toggleResidents = () => {
		setActiveResidents(!activeResidents);
	};

	const toggleActivities = () => {
		setActiveActivities(!activeActivities);
	};

	if (loadError) {
		return <div>Map cannot be loaded right now, sorry.</div>;
	}

	if (!isLoaded) {
		return <div>Map is Loading</div>;
	}

	return (
		<>
			<Navigation
				activeLocations={activeLocations}
				activeResidents={activeResidents}
				activeActivities={activeActivities}
				toggleActivities={toggleActivities}
				toggleResidents={toggleResidents}
				toggleLocations={toggleLocations}
			/>
			<div className="pb-32">
				<div className="rounded-lg overflow-hidden">
					<GoogleMap
						mapContainerStyle={{
							minHeight: "650px",
							width: "100%",
						}}
						zoom={zoom}
						center={center}
					>
						{activeResidents &&
							residents.map((item) => (
								<Marker
									key={item.id}
									item={item}
									type="resident"
								/>
							))}
						{activeLocations &&
							locations.map((item) => (
								<Marker
									key={item.id}
									item={item}
									type="location"
								/>
							))}
						{activeActivities &&
							activities.map((item) => (
								<Marker
									key={item.id}
									item={item}
									type="activity"
								/>
							))}
					</GoogleMap>
				</div>
			</div>
		</>
	);
};

export default memo(Map);
