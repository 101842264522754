// Components
import ListEmpty from "components/list/empty";

const Empty = () => {
    return (
        <ListEmpty
            description=" Er zijn nog geen activiteiten aangemaakt, klik op de knop beneden om
                    een activiteit toe te voegen."
            label="Nieuwe activiteit"
            href="/activiteit-starten"
        />
    );
};

export default Empty;
