import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

const SelectSearch = ({ list, selected, setSelected }) => {
	return (
		<Menu as="div" className="relative  text-left">
			<div>
				<Menu.Button className="h-10 px-4 flex items-center font-semibold text-sm justify-between w-full rounded-md border-2 border-gray-200 focus:border-green-400">
					<span>{selected || "Kies een thema"}</span>
					<ChevronDownIcon
						className="ml-2 h-5 w-5 stroke-2"
						aria-hidden="true"
					/>
				</Menu.Button>
			</div>
			<Transition
				as={Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<Menu.Items className="absolute right-0 mt-2 w-56 z-20 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
					<div className="px-1 py-1 ">
						{list.map((item) => (
							<Menu.Item key={item}>
								{({ active, disabled }) => (
									<button
										className={`${
											active
												? "bg-gray-100"
												: "text-gray-900"
										} 
                                            ${
												selected === item &&
												"text-green-400"
											} group flex w-full items-center rounded-md px-2 py-2 text-sm`}
										onClick={() =>
											selected === item
												? setSelected(null)
												: setSelected(item)
										}
									>
										<span
											className={`h-4 w-4 inline-flex rounded bg-${
												disabled ? "gray" : ""
											}-400 mr-2`}
										/>
										{item}
									</button>
								)}
							</Menu.Item>
						))}
					</div>
				</Menu.Items>
			</Transition>
		</Menu>
	);
};

export default SelectSearch;
