import { Fragment, useState, useEffect } from "react";

// Libraries
import { Combobox, Transition } from "@headlessui/react";

// Core
import {
    query,
    collection,
    onSnapshot,
    orderBy,
    addDoc,
} from "firebase/firestore";
import { db } from "core/firebase";
import { unwrapList } from "core/utils";

// Icons
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { CheckIcon, PlusCircleIcon } from "@heroicons/react/24/outline";

const Labels = ({ selected, setSelected }) => {
    const [search, setSearch] = useState("");
    const [list, setList] = useState(null);

    useEffect(() => {
        const q = query(collection(db, "labels"), orderBy("createdAt", "desc"));

        const unsubscribe = onSnapshot(q, (payload) =>
            setList(unwrapList(payload))
        );

        return () => unsubscribe();
        // eslint-disable-next-line
    }, []);

    const addLabel = async () => {
        const payload = search;

        await addDoc(collection(db, "labels"), {
            name: payload,
            createdAt: new Date(),
        });

        setSearch("");
    };

    const setSelectedWithCustomCheck = (payload) => {
        setSelected(payload);
    };

    const filteredList =
        search === ""
            ? list
            : list.filter((item) =>
                  item.name
                      .toLowerCase()
                      .replace(/\s+/g, "")
                      .includes(search.toLowerCase().replace(/\s+/g, ""))
              );

    const compareLabels = (a, b) => {
        return a.id === b.id;
    };

    if (!list) {
        return null;
    }

    return (
        <>
            <Combobox
                onChange={setSelectedWithCustomCheck}
                by={compareLabels}
                value={selected}
                multiple
            >
                <div className="relative mt-1">
                    <div className="relative w-full h-10 cursor-default overflow-hidden rounded-md bg-white text-left border-2 border-gray-200 focus:border-green-400 sm:text-sm focus:outline-none focus:ring-transparent placeholder:text-gray-400">
                        <Combobox.Input
                            className="w-full border-none pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 placeholder:text-gray-400"
                            displayValue={(item) => item.name}
                            onChange={(event) => setSearch(event.target.value)}
                            placeholder="Type de naam van het label"
                        />

                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </Combobox.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setSearch("")}
                    >
                        <Combobox.Options className="absolute mt-2 max-h-60 w-full overflow-auto rounded-md origin-top-right bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm p-1">
                            {filteredList.length === 0 && query !== "" ? (
                                <div className="relative cursor-default select-none py-2 px-4 text-gray-900">
                                    Label niet gevonden...{" "}
                                    <button
                                        type="button"
                                        onClick={addLabel}
                                        className="font-medium text-green-400 inline-flex items-center"
                                    >
                                        Toevoegen
                                        <PlusCircleIcon className="h-5 w-5 ml-1 stroke-2" />
                                    </button>
                                </div>
                            ) : (
                                filteredList.map((item) => (
                                    <Combobox.Option
                                        key={item.id}
                                        value={item}
                                        className={({ active }) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 rounded ${
                                                active
                                                    ? "bg-gray-200"
                                                    : "text-gray-900"
                                            }`
                                        }
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <span
                                                    className={`block truncate ${
                                                        selected
                                                            ? "font-medium"
                                                            : "font-normal"
                                                    }`}
                                                >
                                                    {item.name}
                                                </span>
                                                {selected ? (
                                                    <span
                                                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                                            active
                                                                ? "text-gray-900"
                                                                : "text-green-400"
                                                        }`}
                                                    >
                                                        <CheckIcon
                                                            className="h-4 w-4 stroke-2"
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Combobox.Option>
                                ))
                            )}
                        </Combobox.Options>
                    </Transition>
                </div>
            </Combobox>
            {selected.map((item) => (
                <div
                    key={item.id}
                    className="px-2 py-1 rounded-lg bg-green-400 mt-2 mr-2 text-white inline-flex text-xs"
                >
                    {item.name}
                </div>
            ))}
        </>
    );
};

export default Labels;
