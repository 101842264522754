// Components
import User from "components/utils/user";
import { dateFull } from "components/utils/dates";
import Link from "components/button/link";

const Item = ({
	id,
	title,
	createdAt,
	createdBy,
	success = [],
	attention = [],
	obstacles = [],
}) => {
	return (
		<div className="grid grid-cols-6 gap-2 lg:grid-cols-10 lg:gap-4 py-2 flex items-center border-t">
			<div className="col-span-1 text-xs font-bold">
				{dateFull(createdAt)}
			</div>
			<div className="col-span-4 text-xs">{title}</div>
			<div className="hidden lg:inline-flex lg:col-span-2">
				<User user={createdBy} />
			</div>
			<div className="hidden lg:inline-flex lg:col-span-2 items-center font-bold text-xs">
				<div className="h-4 w-4 rounded bg-green-400 mr-2" />
				<div className="mr-2">{success.length}</div>
				<div className="h-4 w-4 rounded bg-orange-400 mr-2" />
				<div className="mr-2">{attention.length}</div>
				<div className="h-4 w-4 rounded  bg-red-400 mr-2" />
				<div className="">{obstacles.length}</div>
			</div>
			<div className="col-span-1">
				<Link className="justify-center w-full" href={`logboek/${id}`}>
					<span>Bekijk</span>
				</Link>
			</div>
		</div>
	);
};

export default Item;
