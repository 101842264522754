import { useState } from "react";

// Library
import { v4 } from "uuid";
import { useNavigate } from "react-router-dom";

// Core
import { createLog } from "core/logbook-create/actions";

// Config
import { points } from "./config";

// Icon
import { PlusCircleIcon } from "@heroicons/react/24/outline";

// Components
import Button from "components/button";
import Navigation from "components/navigation/logbook-create";
import Input from "components/inputs/input";
import Label from "components/inputs/label";
import TextArea from "components/inputs/textarea";
import DatePicker from "components/inputs/datepicker";
import DropDown from "components/dropdown";
import List from "./list";
import Labels from "./labels";

const CreateLog = () => {
    const navigate = useNavigate();

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [date, setDate] = useState(null);
    const [labels, setLabels] = useState([]);
    const [selectedPoints, setPoint] = useState([]);
    const [lists, setLists] = useState({});
    const [loading, setLoading] = useState(false);

    const addPoint = (payload) => {
        setPoint([...selectedPoints, payload]);
    };

    const addItem = ({ key, text }) => {
        if (lists[key]) {
            setLists({
                ...lists,
                [key]: [{ id: v4(), text }, ...lists[key]],
            });
        } else {
            setLists({
                ...lists,
                [key]: [{ id: v4(), text }],
            });
        }
    };

    const removeItem = ({ item, id }) => {
        setLists({
            ...lists,
            [item.key]: lists[item.key].filter((item) => item.id !== id),
        });
    };

    const editItem = ({ item, id, text }) => {
        setLists({
            ...lists,
            [item.key]: lists[item.key].map((item) =>
                item.id === id ? { id, text } : item
            ),
        });
    };

    const onSubmitLog = async () => {
        setLoading(true);

        const error = await createLog({
            title,
            description,
            date,
            labels,
            ...lists,
        });

        setLoading(false);

        if (error) {
            console.log(error);
            return;
        }

        navigate("/");
    };

    return (
        <>
            <Navigation />

            <div className="p-6 rounded-md border-2 mb-6">
                <div className="max-w-xl">
                    <Label text="Voeg een titel toe:" className="mb-1" />
                    <Input
                        placeholder="Vul hier een titel in..."
                        value={title}
                        onChange={({ target }) => setTitle(target.value)}
                    />
                    <Label
                        text="Voeg labels toe (max 2):"
                        className="mb-1 mt-6"
                    />
                    <Labels selected={labels} setSelected={setLabels} />
                    <Label text="Voeg een datum toe:" className="mb-1 mt-6" />
                    <DatePicker date={date} onChange={setDate} />
                </div>

                <div>
                    <h1 className="text-2xl mb-2 mt-6">Uitleg</h1>
                    <p className="text-sm font-light loading-snug text-gray-500 max-w-lg">
                        Hieronder vind je alle informatie die bij deze log
                        hoort. Deze informatie kan alleen worden bewerkt door de
                        persoon die de log heeft aangemaakt.
                    </p>
                </div>
                <div className="grid sm:grid-cols-2 sm:gap-10 pt-6">
                    <div>
                        <h1 className="text-2xl mb-2">Notitie</h1>
                        <p className="text-sm font-light loading-snug text-gray-500 mb-2">
                            Maak in je notitie de samenvatting van wat je wilt
                            loggen. Vul daarna de belangrijkste punten uit de
                            notitie in.
                        </p>

                        <TextArea
                            placeholder="Schrijf hier de samenvatting van deze log"
                            rows={6}
                            value={description}
                            onChange={({ target }) =>
                                setDescription(target.value)
                            }
                        />
                    </div>
                    <div>
                        <h1 className="text-2xl mb-2 pt-6 sm:pt-0">
                            Punten uit notitie
                        </h1>
                        <p className="text-sm font-light loading-snug text-gray-500 mb-2">
                            Haal uit je notitie de belangrijkste punten. Dit
                            maakt het makkelijk om de belangrijkste
                            ontwikkelingen in het proces weer te vinden.
                        </p>

                        <div className="flex items-center justify-between mb-2">
                            <Label text="Toevoegen:" />
                            <div className="relative">
                                <DropDown
                                    list={points}
                                    title="Lijst toevoegen"
                                    addItem={addPoint}
                                    selected={selectedPoints}
                                />
                            </div>
                        </div>
                        {selectedPoints.map((item) => (
                            <List
                                key={item.key}
                                list={lists[item.key] || []}
                                item={item}
                                addItem={addItem}
                                removeItem={removeItem}
                                editItem={editItem}
                            />
                        ))}
                    </div>
                </div>
                <div className="py-6">
                    <Button
                        text="Opslaan"
                        onClick={onSubmitLog}
                        loading={loading}
                        disabled={!title?.length}
                    >
                        {!loading && (
                            <PlusCircleIcon className="h-5 w-5 stroke-2 ml-2" />
                        )}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default CreateLog;
