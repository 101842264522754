// Core
import { authStore } from "core/authentication/store";

// Pages
import Login from "pages/login";
import Dashboard from "pages/dashboard";

const App = () => {
    const { isUserAuthenticated } = authStore();

    if (isUserAuthenticated) {
        return <Dashboard />;
    }

    return <Login />;
};

export default App;
