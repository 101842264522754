import { Fragment, useState } from "react";

// Library
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";

// Icons
import {
	RectangleStackIcon,
	UserGroupIcon,
	ArrowRightOnRectangleIcon,
	MapIcon,
	CalendarDaysIcon,
	UsersIcon,
	Bars3BottomLeftIcon,
	MapPinIcon,
	XMarkIcon,
} from "@heroicons/react/24/outline";

// Core
import { removeUserListener } from "core/user/actions";
import { userStore } from "core/user";

// Components
import Thumbnail from "components/images/thumbnail";
import Image from "components/images/image";
import Button from "components/button";
import { Label, Link } from "./utils";

const Sidebar = () => {
	const navigate = useNavigate();

	const [sidebarOpen, setSidebarOpen] = useState(false);
	const { user } = userStore();

	const singOut = () => {
		removeUserListener();
		navigate("/");
	};

	return (
		<>
			<div>
				<Transition.Root show={sidebarOpen} as={Fragment}>
					<Dialog
						as="div"
						className="relative z-40 xl:hidden"
						onClose={setSidebarOpen}
					>
						<Transition.Child
							as={Fragment}
							enter="transition-opacity ease-linear duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="transition-opacity ease-linear duration-300"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
						</Transition.Child>

						<div className="fixed inset-0 z-40 flex">
							<Transition.Child
								as={Fragment}
								enter="transition ease-in-out duration-300 transform"
								enterFrom="-translate-x-full"
								enterTo="translate-x-0"
								leave="transition ease-in-out duration-300 transform"
								leaveFrom="translate-x-0"
								leaveTo="-translate-x-full"
							>
								<Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-navigation">
									<Transition.Child
										as={Fragment}
										enter="ease-in-out duration-300"
										enterFrom="opacity-0"
										enterTo="opacity-100"
										leave="ease-in-out duration-300"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
									>
										<div className="absolute top-0 right-0 -mr-12 pt-2">
											<button
												type="button"
												className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
												onClick={() =>
													setSidebarOpen(false)
												}
											>
												<span className="sr-only">
													Close sidebar
												</span>
												<XMarkIcon
													className="h-6 w-6 text-white"
													aria-hidden="true"
												/>
											</button>
										</div>
									</Transition.Child>
									<div className="flex flex-shrink-0 items-center px-4 h-16 relative">
										<Image
											width="50%"
											src={`${process.env.PUBLIC_URL}/logos/logo-apeldoorn.svg`}
										/>
										<div className="absolute bottom-1 left-11 text-xs font-light text-white">
											ABCD monitoring
										</div>
									</div>
									<div className="mt-5 h-0 flex-1 overflow-y-auto">
										<nav className="space-y-1 px-2">
											<Label text="Kwaliteitskaart modules" />
											<div className="px-2 text-xs uppercase text-gray-400"></div>
											{user?.canReadModule && (
												<Link
													text="Bewoners"
													route="/bewoners"
													Icon={UserGroupIcon}
												/>
											)}
											{user?.canReadModule && (
												<Link
													text="Locaties"
													route="/locaties"
													Icon={MapIcon}
												/>
											)}

											{user?.canReadModule && (
												<Link
													text="Activiteiten"
													route="/activiteiten"
													Icon={CalendarDaysIcon}
												/>
											)}

											{user?.canReadModule && (
												<Link
													text="Map"
													route="/map"
													Icon={MapPinIcon}
												/>
											)}

											{user?.canCreate && (
												<>
													<div className="pt-6"></div>
													<Label text="Proces modules" />
													<Link
														text="Logboek"
														route=""
														Icon={
															RectangleStackIcon
														}
													/>
												</>
											)}
											{user?.canViewUsers && (
												<>
													<div className="pt-6"></div>
													<Label text="settings" />
													<Link
														text="Gebruikers"
														route="gebruikers"
														Icon={UsersIcon}
													/>
												</>
											)}
										</nav>
									</div>
									<div className="flex flex-shrink-0 p-4 border-t border-white/10">
										<Button
											onClick={removeUserListener}
											className="w-full justify-center"
											text="Afmelden"
										>
											<ArrowRightOnRectangleIcon className="h-5 w-5 stroke-2 ml-2" />
										</Button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
							<div
								className="w-14 flex-shrink-0"
								aria-hidden="true"
							>
								{/* Dummy element to force sidebar to shrink to fit close icon */}
							</div>
						</div>
					</Dialog>
				</Transition.Root>

				{/* Static sidebar for desktop */}
				<div className="hidden xl:fixed xl:inset-y-0 xl:flex xl:w-64 xl:flex-col">
					{/* Sidebar component, swap this element with another sidebar if you like */}
					<div className="flex min-h-0 flex-1 flex-col bg-navigation">
						<div className="flex flex-shrink-0 items-center px-4 h-16 relative">
							<Image
								width="50%"
								src={`${process.env.PUBLIC_URL}/logos/logo-apeldoorn.svg`}
							/>
							<div className="absolute bottom-1 left-11 text-xs font-light text-white">
								ABCD monitoring
							</div>
						</div>
						<div className="flex flex-1 flex-col overflow-y-auto">
							<nav className="flex-1 space-y-1 px-2 py-4">
								<Label text="Kwaliteitskaart modules" />
								<div className="px-2 text-xs uppercase text-gray-400"></div>
								{user?.canReadModule && (
									<Link
										text="Bewoners"
										route="/bewoners"
										Icon={UserGroupIcon}
									/>
								)}
								{user?.canReadModule && (
									<Link
										text="Locaties"
										route="/locaties"
										Icon={MapIcon}
									/>
								)}
								{user?.canReadModule && (
									<Link
										text="Activiteiten"
										route="/activiteiten"
										Icon={CalendarDaysIcon}
									/>
								)}
								{user?.canReadModule && (
									<Link
										text="Map"
										route="/map"
										Icon={MapPinIcon}
									/>
								)}
								{user?.canCreate && (
									<>
										<div className="pt-6"></div>
										<Label text="Proces modules" />
										<Link
											text="Logboek"
											route=""
											Icon={RectangleStackIcon}
										/>
									</>
								)}
								{user?.canViewUsers && (
									<>
										<div className="pt-6"></div>
										<Label text="settings" />
										<Link
											text="Gebruikers"
											route="gebruikers"
											Icon={UsersIcon}
										/>
									</>
								)}
							</nav>
						</div>
						<div className="flex flex-shrink-0 p-4 border-t border-white/10">
							<Button
								onClick={singOut}
								className="w-full justify-center"
								text="Afmelden"
							>
								<ArrowRightOnRectangleIcon className="h-5 w-5 stroke-2 ml-2" />
							</Button>
						</div>
					</div>
				</div>
				<div className="flex flex-col xl:pl-64">
					<div className="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white">
						<button
							type="button"
							className="px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-400 xl:hidden"
							onClick={() => setSidebarOpen(true)}
						>
							<span className="sr-only">Open sidebar</span>
							<Bars3BottomLeftIcon
								className="h-6 w-6"
								aria-hidden="true"
							/>
						</button>
						<div className="flex flex-1 justify-between px-4 lg:px-6">
							<div className="flex flex-1"></div>
							<div className="ml-4 flex items-center">
								<Thumbnail user={user} size="w-10 h-10" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Sidebar;
