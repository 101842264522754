// Components
import Admin from "./admin";

const Admins = ({ list = [] }) => {
    return (
        <div className="pt-6 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {list.map((item) => (
                <Admin key={item.id} item={item} />
            ))}
        </div>
    );
};

export default Admins;
