// Components
import User from "components/utils/user";
import { Light } from "components/utils/light";

const Admin = ({ item }) => {
    return (
        <div className="col-span-1 flex flex-col rounded-lg bg-white border-2">
            <div className="flex flex-1 flex-col p-6">
                <User user={item} medium />
                <div className="mt-2 mb flex justify-between pt-2 border-t-2">
                    <div className="text-xs font-bold text-gray-900">
                        Kan aanmaken:
                    </div>
                    <Light active={item.canCreate} />
                </div>
                <div className="mt-2 mb flex justify-between">
                    <div className="text-xs font-bold text-gray-900">
                        Kan aanpassen:
                    </div>
                    <Light active={item.canEdit} />
                </div>
                <div className="mt-2 mb flex justify-between">
                    <div className="text-xs font-bold text-gray-900">
                        Kan lezen:
                    </div>
                    <Light active={item.canRead} />
                </div>
                <div className="mt-2 mb flex justify-between">
                    <div className="text-xs font-bold text-gray-900">
                        Kan avg lezen:
                    </div>
                    <Light active={item.canViewContactInfo} />
                </div>
                <div className="mt-2 mb flex justify-between">
                    <div className="text-xs font-bold text-gray-900">
                        Kan gebruikers zien:
                    </div>
                    <Light ght active={item.canViewUsers} />
                </div>
            </div>
        </div>
    );
};

export default Admin;
