const TextArea = ({
    placeholder = "Type uw tekst hier",
    className = "",
    value = "",
    rows = 6,
    onChange = () => {},
}) => {
    return (
        <textarea
            rows={rows}
            className={`block w-full rounded-md border-2 border-gray-200 focus:border-green-400 sm:text-sm focus:outline-none focus:ring-transparent placeholder:text-gray-400 ${className}`}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
        />
    );
};

export default TextArea;
