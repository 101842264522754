import Image from "components/images/image";

const Logos = () => {
    return (
        <div className="pt-6 pb-4">
            <div className="flex items-center">
                <Image
                    width="40%"
                    src={`${process.env.PUBLIC_URL}/logos/logo-apeldoorn.svg`}
                />
                <span className="inline-flex bg-green-400 rounded px-2 h-5 text-green-700 ml-2 text-xs items-center font-medium">
                    beta versie
                </span>
            </div>
        </div>
    );
};

export default Logos;
