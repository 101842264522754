// Utils
import { dateFull } from "components/utils/dates";

// Components
import Thumbnail from "components/images/thumbnail";
import Paragraph from "components/text/paragraph";
import Label from "components/text/label";

const Note = ({ text = "", createdBy = null, createdAt = null }) => {
    return (
        <div className="relative pb-8">
            <span
                className="absolute top-5 left-5 -ml-[2px] h-full w-0.5 bg-gray-200"
                aria-hidden="true"
            />
            <div className="relative flex items-start space-x-3">
                <div className="relative">
                    <Thumbnail user={createdBy} size="w-10 h-10" />
                </div>
                <div className="min-w-0 flex-1">
                    <div>
                        <Label
                            text={`${createdBy.firstName} ${createdBy.lastName}`}
                            size="text-sm"
                        />

                        <p className="text-sm text-gray-400">
                            Notitie aangemaakt op: {dateFull(createdAt)}
                        </p>
                    </div>
                    {text && <Paragraph className="mt-2" text={text} />}
                </div>
            </div>
        </div>
    );
};

export default Note;
