const FormTitle = ({ Icon = null, title, description }) => {
	return (
		<div className="flex">
			<div>
				<div className="h-12 w-12 rounded-md bg-green-400/30 flex justify-center items-center">
					{Icon && (
						<Icon className="stroke-2 h-6 w-6 text-green-400" />
					)}
				</div>
			</div>
			<div className="ml-4 mt-1">
				<h1 className="font-headings tracking-tight text-xl-tight 2xl:2xl-tight font-medium -mt-1.5 text-base">
					{title}
				</h1>
				<p className="font-copy 2xl:text-md font-light text-sm">
					{description}
				</p>
			</div>
		</div>
	);
};

export default FormTitle;
