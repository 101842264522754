import React, { useState } from "react";

const Image = ({
    src = "",
    height = "auto",
    width = "auto",
    alt = "",
    className = "",
}) => {
    const [loaded, toggleLoading] = useState(false);

    return (
        <img
            src={src}
            onLoad={() => toggleLoading(true)}
            className={`img-animated ${
                loaded ? "opacity-100" : "opacity-0"
            } ${className}`}
            height={height}
            width={width}
            alt={alt}
        ></img>
    );
};

export default Image;
