// Libraries
import { onAuthStateChanged } from "firebase/auth";

// Firebase
import { auth } from "core/firebase";

// Actions
import { setUserListener } from "core/user";

export * from "./actions";

export const checkUserAuthentication = () =>
    new Promise((resolve, reject) => {
        const unsubscribe = onAuthStateChanged(
            auth,
            (user) => {
                user && setUserListener(user);
                unsubscribe();
                resolve();
            },
            (error) => reject(error)
        );
    });
