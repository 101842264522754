import { useState } from "react";

// Libraries
import { Link } from "react-router-dom";

// Core
import { signInWithEmail } from "core/authentication";

// Utils
import { errorMessage } from "./config";

// Components
import Logos from "./logos";
import Input from "components/inputs/input";
import Label from "components/inputs/label";
import Button from "components/button";
import Alert from "components/alerts";

const Login = () => {
    const [loading, setLoading] = useState(false);

    const [error, setError] = useState(false);
    const [errorCode, setErrorCode] = useState(null);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const onSubmitLogin = async (e) => {
        e.preventDefault();

        setError(false);
        setLoading(true);

        const response = await signInWithEmail(email, password);

        setLoading(false);

        if (response) {
            setError(true);
            setErrorCode(response);
        }
    };

    return (
        <div className="px-4 pb-8 md:px-8 bg-white rounded-md w-full max-w-md shadow-md">
            <Logos />

            <form onSubmit={onSubmitLogin} noValidate>
                <div className="mb-4">
                    <Label text="Email" />
                    <Input
                        type="email"
                        placeholder="you@example.com"
                        value={email}
                        onChange={({ target }) => setEmail(target.value)}
                    />
                </div>
                <Label text="Wachtwoord" />
                <Input
                    type="password"
                    placeholder="Wachtwoord"
                    value={password}
                    onChange={({ target }) => setPassword(target.value)}
                />
                <div className="mt-4 flex items-center">
                    <Button
                        type="submit"
                        text="Inloggen"
                        loading={loading}
                        disabled={!email.length || !password.length}
                    />
                    <p className="text-sm ml-4">
                        Wachtwoord vergeten?
                        <Link
                            to={"reset"}
                            className="text-green-400 font-bold ml-2"
                        >
                            Klik hier
                        </Link>
                    </p>
                </div>
            </form>
            {error && (
                <div className="mt-4">
                    <Alert error={true} text={errorMessage(errorCode)} />
                </div>
            )}
        </div>
    );
};

export default Login;
