const ListEmpty = ({ title = "Deze lijst is leeg", description = "" }) => {
    return (
        <div className="flex justify-center">
            <div className="relative block max-w-2xl  w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center mt-12">
                <h1 className="text-2xl font-medium text-gray-900">{title}</h1>
                <p className="mt-1 text-base text-gray-400">{description}</p>
            </div>
        </div>
    );
};

export default ListEmpty;
