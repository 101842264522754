import { useState, useEffect } from "react";

// Core
import {
	collection,
	query,
	onSnapshot,
	orderBy,
	where,
} from "firebase/firestore";
import { db } from "core/firebase";
import { unwrapList } from "core/utils";

// Components
import Navigation from "components/navigation/locations";
import Loading from "components/list/loading";
import ListEmpty from "components/list/locations/empty";
import List from "components/list/locations/list";

const Locations = () => {
	const [list, setList] = useState(null);
	const [searchList, setSearchList] = useState([]);
	const [search, setSearch] = useState("");

	useEffect(() => {
		const q = query(
			collection(db, "locations"),
			where("visibility", "==", true),
			orderBy("createdAt", "desc")
		);

		const unsubscribe = onSnapshot(q, (payload) => {
			setList(unwrapList(payload));
		});

		return () => unsubscribe();
	}, []);

	useEffect(() => {
		if (!list) return;

		if (!search || !search.length) {
			setSearchList(list);
		} else {
			const result = list.filter(({ name }) =>
				name.toLowerCase().includes(search.toLowerCase())
			);
			setSearchList(result);
		}
	}, [search, list]);

	return (
		<>
			<Navigation search={search} setSearch={setSearch} />
			{list === null && <Loading />}
			{list && searchList?.length === 0 && <ListEmpty />}
			{searchList?.length > 0 && <List list={searchList} />}
		</>
	);
};

export default Locations;
