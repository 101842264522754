import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

const DropDownMenu = ({ title, list, selected, addItem }) => {
    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button className="inline-flex items-center w-full justify-center rounded-md bg-green-400 px-4 h-9 text-sm font-medium text-white hover:bg-green-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-green focus-visible:ring-opacity-75">
                    {title}
                    <ChevronDownIcon
                        className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100 stroke-2"
                        aria-hidden="true"
                    />
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 mt-2 w-56 z-20 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-1 py-1 ">
                        {list.map((item) => (
                            <Menu.Item
                                key={item.key}
                                disabled={selected.some(
                                    (payload) => payload.key === item.key
                                )}
                            >
                                {({ active, disabled }) => (
                                    <button
                                        className={`${
                                            active
                                                ? "bg-gray-100"
                                                : "text-gray-900"
                                        } 
                                            ${
                                                disabled &&
                                                "text-gray-200 cursor-not-allowed"
                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                        onClick={() => addItem(item)}
                                    >
                                        <span
                                            className={`h-4 w-4 inline-flex rounded bg-${
                                                disabled ? "gray" : item.color
                                            }-400 mr-2`}
                                        />
                                        {item.name}
                                    </button>
                                )}
                            </Menu.Item>
                        ))}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    );
};

export default DropDownMenu;

/*
 <Menu.Item>
    {({ active }) => (
        <button
            className={`${
                active
                    ? "bg-violet-500 text-white"
                    : "text-gray-900"
            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
        >
            {active ? (
                <EditActiveIcon
                    className="mr-2 h-5 w-5"
                    aria-hidden="true"
                />
            ) : (
                <EditInactiveIcon
                    className="mr-2 h-5 w-5"
                    aria-hidden="true"
                />
            )}
            Edit
        </button>
    )}
</Menu.Item>
*/
