import { createBrowserRouter } from "react-router-dom";

// Pages
import Root from "./root";

import Logbook from "./logbook";
import CreateLog from "./logbook-create";
import EditLog from "./logbook-edit";

import Residents from "./residents";
import CreateResident from "./resident-create";
import EditResident from "./resident-edit";

import Locations from "./locations";
import CreateLocation from "./locations-create";
import EditLocation from "./locations-edit";

import Activities from "./activities";
import CreateActivity from "./activities-create";
import EditActivity from "./activities-edit";

import Map from "./map";

import Users from "./users";
import ErrorPage from "./error";

const router = createBrowserRouter([
	{
		path: "/",
		element: <Root />,
		errorElement: <ErrorPage />,
		children: [
			// Logbook
			{
				path: "",
				element: <Logbook />,
			},
			{
				path: "log-starten",
				element: <CreateLog />,
			},
			{
				path: "logboek/:id",
				element: <EditLog />,
			},

			// Residents
			{
				path: "bewoners",
				element: <Residents />,
			},
			{
				path: "bewoner-starten",
				element: <CreateResident />,
			},
			{
				path: "bewoner/:id",
				element: <EditResident />,
			},

			// Locations
			{ path: "locaties", element: <Locations /> },
			{ path: "locatie-starten", element: <CreateLocation /> },
			{ path: "locatie/:id", element: <EditLocation /> },

			// Activities
			{ path: "activiteiten", element: <Activities /> },
			{ path: "activiteit-starten", element: <CreateActivity /> },
			{ path: "activiteit/:id", element: <EditActivity /> },

			// Map
			{ path: "map", element: <Map /> },

			// Users
			{
				path: "gebruikers",
				element: <Users />,
			},
		],
	},
]);

export { router };
