import { useState } from "react";

// Icon
import {
	TrashIcon,
	ArrowLeftCircleIcon,
	PencilSquareIcon,
} from "@heroicons/react/24/outline";

// Components
import Button from "components/button/border";
import Input from "components/inputs/input";

const Item = ({
	id,
	text = "",
	item,
	editItem,
	removeItem,
	canEdit = false,
}) => {
	const [edit, setEdit] = useState(false);
	const [updatedText, setText] = useState(text);

	if (edit) {
		return (
			<>
				<div className="flex items-center mb-2 mt-2">
					<Input
						placeholder="Type een omschrijving"
						value={updatedText}
						onChange={({ target }) => setText(target.value)}
					/>
				</div>
				<div className="flex items-center">
					<Button onClick={() => setEdit(false)} className="mr-4">
						<ArrowLeftCircleIcon className="w-5 h-5 stroke-2" />
					</Button>
					<Button
						text="Opslaan"
						onClick={() => {
							editItem({
								item,
								id,
								text: updatedText,
							});
							setEdit(false);
						}}
						disabled={!text?.length}
					></Button>
					<Button
						onClick={() => removeItem({ item, id })}
						className="bg-red-400 text-red-700 ml-4"
					>
						<TrashIcon className="w-5 h-5 stroke-2" />
					</Button>
				</div>
			</>
		);
	}
	return (
		<div
			className="py-2 flex items-center justify-between"
			onClick={canEdit ? () => setEdit(true) : () => {}}
		>
			<p className="text-sm font-light loading-snug text-gray-500">
				{text}{" "}
				{canEdit && (
					<PencilSquareIcon className="inline-block h-5 w-5 stroke-2 text-gray-600" />
				)}
			</p>
		</div>
	);
};

export default Item;
