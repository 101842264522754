// Utils
import { dateFull } from "components/utils/dates";

// Components
import Label from "components/labels/label-note";

const Log = ({ editKey = "", createdBy = {}, createdAt = "" }) => {
    return (
        <div className="text-sm min-w-0 flex-1 py-1.5">
            <Label text={editKey || ""} />{" "}
            <span className="font-semibold tracking-tight text-xl-tight">
                {createdBy.firstName}
            </span>{" "}
            <span className="whitespace-nowrap">op {dateFull(createdAt)}</span>{" "}
        </div>
    );
};

export default Log;
