import { create } from "zustand";

const initialState = {
    active: false,
    error: false,
    message: "",
};

export const notificationStore = create((set) => ({
    ...initialState,
    openNotification: ({ error = false, message = "" }) =>
        set({
            active: true,
            error,
            message,
        }),
    closeNotification: () =>
        set({
            active: false,
        }),
}));
