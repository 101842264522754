// Components
import Item from "./item";

const List = ({ list, selected, editId, toggleLocation }) => {
	return (
		<div className="space-y-4 overflow-y-scroll max-h-96 pr-2">
			{list
				.sort((a, b) => !!selected[b.id] - !!selected[a.id])
				.map((item) => (
					<Item
						key={item.id}
						{...item}
						selected={selected}
						editId={editId}
						toggleLocation={toggleLocation}
					/>
				))}
		</div>
	);
};

export default List;
