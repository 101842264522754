// Components
import ListEmpty from "components/list/empty";

const Empty = () => {
	return (
		<ListEmpty
			description=" Er zijn nog geen locaties aangemaakt, klik op de knop beneden om
                    een locatie toe te voegen."
			label="Nieuwe locatie"
			href="/locatie-starten"
		/>
	);
};

export default Empty;
