import { useState } from "react";

// Libraries
import { Link } from "react-router-dom";

// Core
import { resetWithEmail } from "core/authentication";

// Utils
import { errorMessage } from "./config";

// Components
import Logos from "./logos";
import Input from "components/inputs/input";
import Label from "components/inputs/label";
import Button from "components/button";
import Alert from "components/alerts";

const Reset = () => {
    const [loading, setLoading] = useState(false);

    const [error, setError] = useState(false);
    const [errorCode, setErrorCode] = useState(null);

    const [email, setEmail] = useState("");

    const onSubmitLogin = async (e) => {
        e.preventDefault();

        setLoading(true);
        setError(false);

        const response = await resetWithEmail(email);

        setLoading(false);
        console.log(response);
        if (response) {
            setError(true);
            setErrorCode(response);
        }
    };

    return (
        <div className="px-8 pb-8 bg-white rounded-md w-full max-w-md shadow-md">
            <Logos />
            <Alert
                info
                text=" Er wordt een email gestuurd met daarin een link om je
                    wachtwoord te herstellen."
            />

            <form onSubmit={onSubmitLogin} noValidate>
                <div className="mb-4 mt-2">
                    <Label text="Email" />
                    <Input
                        type="email"
                        placeholder="you@example.com"
                        value={email}
                        onChange={({ target }) => setEmail(target.value)}
                    />
                </div>

                <div className="mt-4 flex items-center">
                    <Button
                        type="submit"
                        text="Stuur een email"
                        loading={loading}
                        disabled={!email.length}
                    />

                    <p className="text-sm ml-4">
                        Terug naar inloggen
                        <Link to="/" className="text-green-400 font-bold ml-2">
                            Klik hier
                        </Link>
                    </p>
                </div>
            </form>
            {error && (
                <div className="mt-4">
                    <Alert error={true} text={errorMessage(errorCode)} />
                </div>
            )}
        </div>
    );
};

export default Reset;
