import { useState } from "react";

// Libraries
import Outlet from "./outlet";

// Components
import Sidebar from "components/sidebar";
import Notification from "components/notification";

const Root = () => {
    const [openSidebar, setSidebar] = useState(false);

    return (
        <div className="antialiased">
            <Sidebar openSidebar={openSidebar} setSidebar={setSidebar} />
            <Notification />
            <Outlet />
        </div>
    );
};

export default Root;
