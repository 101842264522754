const PageUserNotFound = () => {
    return (
        <div className="h-screen w-screen flex justify-center items-center">
            <div className="max-w-sm">
                <h1 className="text-2xl text-center">
                    Account niet gevonden...
                </h1>
            </div>
        </div>
    );
};

export default PageUserNotFound;
