import { NavLink } from "react-router-dom";

export const Label = ({ text = "", disabled = false }) => {
    return (
        <div
            className={`px-2 text-xs uppercase ${
                disabled ? "text-white/20" : "text-white/60"
            }`}
        >
            {text}
        </div>
    );
};

export const Link = ({
    text = "",
    route = "",
    Icon = null,
    disabled = false,
}) => {
    if (disabled) {
        return (
            <button
                type="button"
                className="group flex items-center px-2 py-2 text-sm font-medium text-white/20 cursor-not-allowed"
            >
                <Icon className="mr-3 h-5 w-5 flex-shrink-0" />
                {text}
            </button>
        );
    }
    return (
        <NavLink
            to={route}
            disabled
            className={({ isActive }) =>
                `group flex items-center px-2 py-2 text-sm font-medium
                ${isActive ? "text-white" : "text-white/60"}`
            }
        >
            <Icon className="mr-3 h-5 w-5 flex-shrink-0" />
            {text}
        </NavLink>
    );
};
