import { useState } from "react";

// Icons
import {
    WrenchScrewdriverIcon,
    XMarkIcon,
    ArrowsUpDownIcon,
} from "@heroicons/react/24/outline";

// Components
import Log from "./update.log";

const List = ({ list }) => {
    const [active, setActive] = useState(false);

    return (
        <div className="relative pb-8">
            <span
                className="absolute top-5 left-5 -ml-[2px] h-full w-0.5 bg-gray-200"
                aria-hidden="true"
            />
            <div className="relative flex items-start space-x-3">
                <div>
                    <div className="ml-[2px] w-8 h-8 rounded-md bg-green-100 inline-flex items-center justify-center">
                        <WrenchScrewdriverIcon className="h-5 w-5 text-green-400 stroke-2" />
                    </div>
                </div>

                <div className="text-sm min-w-0 flex-1 px-2 py-2 border-2 rounded-md">
                    <div className={`${active && "mb-2"} `}>
                        Er zijn {list.length} items aangepast
                        <button
                            type="button"
                            onClick={() => setActive(!active)}
                            className="font-semibold inline-flex item-center text-green-400 ml-2"
                        >
                            Open items{" "}
                            {active ? (
                                <XMarkIcon className="h-5 w-5 stroke-2 ml-2" />
                            ) : (
                                <ArrowsUpDownIcon className="h-5 w-5 stroke-2 ml-2" />
                            )}
                        </button>
                    </div>
                    {active
                        ? list.map((item) => <Log key={item.id} {...item} />)
                        : null}
                </div>
            </div>
        </div>
    );
};

export default List;
