const ButtonBorder = ({
	text = "",
	onClick,
	className = "",
	children = null,
	disabled = false,
	color = "bg-gray-100 text-gray-400",
	height = "h-9",
}) => {
	return (
		<button
			type="button"
			onClick={onClick}
			disabled={disabled}
			className={`relative flex items-center rounded-md px-3 text-sm font-semibold ${className} ${color} ${height}`}
		>
			{children}
			{text}
		</button>
	);
};

export default ButtonBorder;
