import { useState } from "react";

// Libraries
import Datepicker from "tailwind-datepicker-react";

// Icons
import {
    ArrowLeftCircleIcon,
    ArrowRightCircleIcon,
} from "@heroicons/react/24/outline";

// Date
import { months } from "components/utils/dates";

const options = {
    title: "",
    autoHide: true,
    todayBtn: false,
    clearBtn: false,
    maxDate: new Date("2030-01-01"),
    minDate: new Date("2022-01-01"),
    theme: {
        background: "bg-white dark:bg-navigation",
        todayBtn: "",
        clearBtn: "",
        icons: "dark:bg-navigation ",
        text: "",
        disabledText: "text-red-400",
        input: "hidden",
        inputIcon: "hidden",
        selected: "bg-green-400",
    },
    icons: {
        // () => ReactNode | JSX.Element
        prev: () => <ArrowLeftCircleIcon className="h-5 w-5 stroke-2" />,
        next: () => <ArrowRightCircleIcon className="h-5 w-5 stroke-2" />,
    },
    datepickerClassNames: "top-12",
    // defaultDate: new Date(),
    language: "nl",
};

const DateSelector = ({ date, onChange = () => {} }) => {
    const [show, setShow] = useState(false);

    const handleChange = (payload) => {
        onChange(payload);
    };
    const handleClose = (state) => {
        setShow(state);
    };

    const handleDisplay = (payload) => {
        if (payload?.seconds) {
            const date = payload.toDate();
            const day = date.getDate();
            const month = date.getMonth();
            const year = date.getFullYear();

            return `${day} ${months[month]} ${year}`;
        }

        const day = payload.getDate();
        const month = payload.getMonth();
        const year = payload.getFullYear();

        return `${day} ${months[month]} ${year}`;
    };

    return (
        <div className="w-full">
            <Datepicker
                options={{
                    ...options,
                    defaultDate: date?.seconds ? date.toDate() : new Date(),
                }}
                onChange={handleChange}
                show={show}
                setShow={handleClose}
            />

            <input
                type="text"
                className="h-10 block w-full rounded-md border-2 border-gray-200 focus:border-green-400 sm:text-sm focus:outline-none focus:ring-transparent placeholder:text-gray-400"
                placeholder="Selecteer een datum"
                value={date ? handleDisplay(date) : ""}
                onFocus={() => setShow(true)}
                readOnly
            />
        </div>
    );
};

export default DateSelector;
