import { useState } from "react";

// Icon
import {
    PencilSquareIcon,
    ArrowLeftCircleIcon,
} from "@heroicons/react/24/outline";

// Components
import AutoComplete from "components/inputs/address";
import Button from "components/button/border";

const EditAddress = ({ label, value, id, editKey, editFunction }) => {
    const [edit, setEdit] = useState(false);
    const [location, setLocation] = useState(value);

    const openEdit = () => {
        setEdit(true);
    };

    const editLocation = async () => {
        await editFunction(id, editKey, location);
        setEdit(false);
    };

    if (edit) {
        return (
            <div className="py-2">
                <div className="text-sm font-semibold mb-2">{label}</div>
                <AutoComplete onChange={setLocation} />

                <div className="flex items-center mt-4">
                    <Button onClick={() => setEdit(false)} className="mr-4">
                        <ArrowLeftCircleIcon className="w-5 h-5 stroke-2" />
                    </Button>
                    <Button
                        text="Opslaan"
                        color="bg-green-400 text-green-700"
                        onClick={editLocation}
                    ></Button>
                </div>
            </div>
        );
    }

    return (
        <div className="grid grid-cols-6 flex items-center py-2">
            <div className="col-span-2">
                <div className="text-sm font-semibold">{label}</div>
            </div>
            <div className="text-sm col-span-4 font-light flex justify-between items-center">
                <span>{value?.address}</span>

                <button className="ml-4 p-2" type="button" onClick={openEdit}>
                    <PencilSquareIcon className="h-5 w-5 stroke-2 text-gray-600" />
                </button>
            </div>
        </div>
    );
};

export default EditAddress;
