// Libraries
import { Outlet } from "react-router-dom";

const PageOutLet = () => {
    return (
        <main className="xl:pl-64 min-h-screen">
            <div className="px-4 xl:px-6">
                <Outlet />
            </div>
        </main>
    );
};

export default PageOutLet;
