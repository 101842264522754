// Utils
import { dateFull } from "components/utils/dates";

// Components
import User from "components/utils/user";
import Link from "components/button/link";
import Ping from "components/utils/ping";

const Item = ({
	id,
	firstName,
	lastName,
	createdAt,
	createdBy,
	notesSize = 0,
	avg = false,
	alarm = false,
	activitySize = 0,
	ideas = [],
}) => {
	return (
		<div className="grid grid-cols-6 gap-2 lg:grid-cols-10 lg:gap-4 py-2 flex items-center border-t">
			<div className="col-span-2 text-xs">
				<div className="inline-flex items-center">
					{alarm && <Ping className="mr-2" />}
					{dateFull(createdAt)}
				</div>
			</div>
			<div className="col-span-2 text-xs">
				{firstName} {lastName}
			</div>
			<div className="hidden lg:inline-flex lg:col-span-2">
				<User user={createdBy} textSize="text-xs" />
			</div>
			<div className="hidden lg:inline-flex lg:col-span-1 text-xs">
				{notesSize}
			</div>
			<div className="hidden lg:inline-flex lg:col-span-1 text-xs">
				{activitySize}
			</div>
			<div className="hidden lg:inline-flex lg:col-span-1">
				<div
					className={`h-4 w-4 rounded  mr-2 ${
						avg ? "bg-green-400" : "bg-red-400"
					}`}
				/>
			</div>

			<div className="col-span-1">
				<Link className="" href={`/bewoner/${id}`}>
					<span>Bekijk</span>
				</Link>
			</div>
		</div>
	);
};

export default Item;
