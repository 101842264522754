export const themes = [
	"Eenzaamheid",
	"Veiligheid",
	"Kinderactiviteit",
	"Jongerenactiviteit",
	"Groen",
	"Duurzaamheid",
	"Sport en spel",
	"Creatief",
	"Sociaal",
	"Overige activiteiten",
];

export const frequencies = [
	"dagelijks",
	"per week",
	"twee wekelijks",
	"per maand",
	"per halfjaar",
	"per jaar",
	"per kwartaal",
	"sporadisch",
];

export const moments = ["Ochtend", "Middag", "Avond", "Nacht", "n.v.t."];

export const scales = ["Huishouden", "Straat", "Wijk", "Stad"];

export const sizes = [
	"0 personen",
	"3-5 personen",
	"5-10 peronen",
	"10-20 peronen",
	"20-50 personen",
	" > 50+ personen",
];

export const statuses = [
	"Potentieel",
	"In ontwikkeling",
	"In uitvoering",
	"Is gerealiseerd",
];

export const points = [
	{
		name: "Succesmoment",
		key: "success",
		color: "green",
	},
	{ name: "Schuurfeestje", key: "attention", color: "orange" },
	{
		name: "Pas op de plaats moment",
		key: "obstacles",
		color: "red",
	},
];
