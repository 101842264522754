import { create } from "zustand";

export const authState = {
    isUserAuthenticated: false,
    isUserLoaded: false,
    isUserNotFound: false,
};

export const authStore = create(() => ({
    ...authState,
}));
