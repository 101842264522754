const Label = ({ text = "", size = "text-base", className = "" }) => {
    return (
        <div
            className={`font-headings tracking-tight text-xl-tight 2xl:2xl-tight font-medium ${size} ${className}`}
        >
            {text}
        </div>
    );
};

export default Label;
