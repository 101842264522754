import { useState } from "react";

// Libraries
import {
    XCircleIcon,
    InformationCircleIcon,
    ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { motion } from "framer-motion";

const height = {
    open: {
        height: "3.4rem",
    },
    closed: {
        height: "0rem",
        transition: {
            delay: 0.4,
            ease: "linear",
        },
    },
};

const opacity = {
    open: {
        opacity: 1,
        transition: {
            delay: 1,
        },
    },

    closed: {
        opacity: 0,
        transition: {
            delay: 0,
        },
    },
};

const Alert = ({ error = false, info = false, text = "", className = "" }) => {
    const [dismissed, setDismissed] = useState(false);

    return (
        <motion.div
            initial={{
                height: "0rem",
                transition: {
                    height: {
                        ease: "linear",
                        delay: 0.2,
                    },
                },
            }}
            animate={dismissed ? "closed" : "open"}
            variants={height}
            className={`relative rounded-lg px-2 md:px-3 flex items-center 
            ${!dismissed && className}
            ${error && "bg-red-100 text-red-400"}
            ${info && "bg-yellow-100 text-yellow-600"}
            `}
        >
            <motion.div className="flex w-full" variants={opacity}>
                <div>
                    <div
                        className={`flex items-center justify-center rounded-lg w-10 h-10 ${
                            error && "text-red-200"
                        } ${info && "bg-yellow-200"}`}
                    >
                        {info && (
                            <InformationCircleIcon
                                className="h-5 w-5 stroke-2"
                                aria-hidden="true"
                            />
                        )}
                        {error && (
                            <ExclamationTriangleIcon
                                className="h-5 w-5 stroke-2"
                                aria-hidden="true"
                            />
                        )}
                    </div>
                </div>
                <div className="ml-2 md:ml-4 w-full flex justify-between items-center">
                    <p className="text-sm">{text}</p>
                    <button type="buttons" onClick={() => setDismissed(true)}>
                        <XCircleIcon
                            className="h-5 w-5  stroke-2"
                            aria-hidden="true"
                        />
                    </button>
                </div>
            </motion.div>
        </motion.div>
    );
};

export default Alert;

/*
  <div className="flex">
                <div className="flex rounded-lg bg-opacity-25 p-2">
                    <MegaphoneIcon
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                    />
                </div>
                <div className="ml-4 flex-1 md:flex md:justify-between">
                    <p className="text-sm text-white">{text}</p>
                    <button onClick={() => setDismissed(true)}>
                        <XCircleIcon
                            className="h-5 w-5 text-white"
                            aria-hidden="true"
                        />
                    </button>
                </div>
            </div>
<motion.div
                variants={variants}
                className="rounded-lg bg-blue p-2 sm:p-3 mb-4 flex items-center"
            >
                <span className="flex rounded-lg bg-opacity-25 p-2 mr-2">
                    <MegaphoneIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                    />
                </span>
                <div className="text-sm text-white">
                    Er wordt een email gestuurd met daarin een link om je
                    wachtwoord te herstellen.
                </div>
            </motion.div>
*/
