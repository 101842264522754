import { useState } from "react";

// Date
import { dateFull } from "components/utils/dates";

// Icons
import { ArrowLeftIcon } from "@heroicons/react/24/outline";

// Components
import DateSelector from "components/inputs/datepicker";

const EditDate = ({ date = null, setDate, canEdit }) => {
    const [edit, setEdit] = useState(false);

    const handleChange = (payload) => {
        setDate(payload);
        setEdit(false);
    };

    if (edit) {
        return (
            <div className="w-full flex items-center mb-6 mt-2">
                <button
                    type="button"
                    onClick={() => setEdit(false)}
                    className="inline-flex mr-2 h-10 w-10 rounded items-center justify-center  bg-gray-100 text-gray-400"
                >
                    <ArrowLeftIcon className="h-5 w-5 stroke-2" />
                </button>
                <DateSelector date={date} onChange={handleChange} />
            </div>
        );
    }

    return (
        <div className="mb-6 mt-2 flex">
            <div className="text-xs font-bold text-gray-900 mr-2">
                Casus datum:
            </div>
            {!date ? (
                <span className="inline-flex text-xs text-gray-400">
                    Geen datum
                </span>
            ) : (
                <span className="inline-flex text-xs ">{dateFull(date)}</span>
            )}
            {canEdit && (
                <button
                    onClick={() => setEdit(true)}
                    className="font-bold text-xs ml-2 text-green-400"
                >
                    aanpassen
                </button>
            )}
        </div>
    );
};

export default EditDate;
