// Utils
import { dateShort } from "components/utils/dates";

// Components
import User from "components/utils/user";

const Meta = ({ createdBy = null, createdAt = null, updatedAt = null }) => {
    return (
        <div className="flex items-center justify-between">
            <div className="mr-4">
                <div className="font-headings tracking-tight text-xl-tight 2xl:2xl-tight font-medium text-sm mb">
                    Aangemaakt door:
                </div>
                {createdBy && <User user={createdBy} />}
            </div>
            <div className="mr-4">
                <div className="font-headings tracking-tight text-xl-tight 2xl:2xl-tight font-medium text-sm mb">
                    Aangemaakt op:
                </div>
                <div className="font-copy 2xl:text-md font-light text-sm">
                    {createdAt ? dateShort(createdAt) : "Geen datum"}
                </div>
            </div>

            <div>
                <div className="font-headings tracking-tight text-xl-tight 2xl:2xl-tight font-medium text-sm mb">
                    Laatst aangepast:
                </div>
                <div className="font-copy 2xl:text-md font-light text-sm">
                    {updatedAt ? dateShort(updatedAt) : "Nog niet aangepast"}
                </div>
            </div>
        </div>
    );
};

export default Meta;
