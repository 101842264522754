import { useState } from "react";

// Libraries
import { notificationStore } from "core/notification/store";

// Icon
import {
    PencilSquareIcon,
    ArrowLeftCircleIcon,
} from "@heroicons/react/24/outline";

// Components
import Input from "components/inputs/input";
import Button from "components/button/border";

const EditText = ({ label, value, id, editKey, editFunction }) => {
    const { openNotification } = notificationStore();

    const [edit, setEdit] = useState(false);
    const [text, setText] = useState(value);

    const openEdit = () => {
        setEdit(true);
    };

    const editText = async () => {
        const error = await editFunction(id, editKey, text);

        if (error) {
            openNotification({
                error: true,
                message: "Fout bij het aanpassen van de tekst",
            });
        } else {
            openNotification({ message: "Tekst succesvol aangepast" });
        }

        setEdit(false);
    };

    if (edit) {
        return (
            <div className="py-2">
                <div className="text-sm font-semibold mb-2">{label}</div>
                <Input
                    placeholder="Type een omschrijving"
                    value={text}
                    onChange={({ target }) => setText(target.value)}
                />

                <div className="flex items-center mt-4">
                    <Button onClick={() => setEdit(false)} className="mr-4">
                        <ArrowLeftCircleIcon className="w-5 h-5 stroke-2" />
                    </Button>
                    <Button
                        text="Opslaan"
                        color="bg-green-400 text-green-700"
                        onClick={editText}
                    />
                </div>
            </div>
        );
    }

    return (
        <div className="grid grid-cols-6 flex items-center py-2">
            <div className="col-span-2">
                <div className="text-sm font-semibold">{label}</div>
            </div>
            <div className="text-sm col-span-4 font-light flex justify-between items-center">
                <span>{value}</span>

                <button className="ml-4 p-2" type="button" onClick={openEdit}>
                    <PencilSquareIcon className="h-5 w-5 stroke-2 text-gray-600" />
                </button>
            </div>
        </div>
    );
};

export default EditText;
