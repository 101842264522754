import { useState, useEffect, useRef } from "react";

// Libraries
import { useParams } from "react-router-dom";

// Core
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "core/firebase";
import { unwrapSnapshot } from "core/utils";
import { updateResident } from "core/resident/actions";

// Icons
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

// Components
import Loading from "components/list/loading";
import Navigation from "components/navigation/resident-edit";
import Meta from "components/edit/meta";
import Ping from "components/utils/ping";
import Details from "./details";
import Notes from "./notes";
import Relations from "./edit-relations";

const EditResident = () => {
	const { id } = useParams();
	const [resident, setResident] = useState(null);

	const ref = useRef(doc(db, "residents", id));

	useEffect(() => {
		const unsubscribe = onSnapshot(ref.current, (payload) =>
			setResident(unwrapSnapshot(payload))
		);

		return () => unsubscribe();
	}, [id]);

	const editVisibility = () => {
		updateResident(resident.id, "visibility", !resident.visibility);
	};

	return (
		<>
			<Navigation />
			{!resident && <Loading />}
			{resident && (
				<div className="flex grid lg:grid-cols-5 lg:gap-10">
					<div className="col-span-3 pb-6">
						<div className="mb-6 mt-2">
							<div className="font-headings tracking-tight text-xl-tight 2xl:2xl-tight font-medium text-sm">
								Naam bewoner:
							</div>

							<h1 className="text-4xl max-w-xl flex items-center relative">
								{resident.alarm && <Ping />}
								{resident.firstName} {resident.lastName}{" "}
							</h1>
						</div>
						<Meta {...resident} />

						<Relations {...resident} />
						<Details {...resident} />
						<>
							<h1 className="text-2xl mb-2 pt-6">Archiveren</h1>
							{resident.visibility ? (
								<div className="flex relative rounded-lg p-2 flex items-center bg-red-100 text-red-500 max-w-lg">
									<div className="flex items-center justify-center rounded-lg w-10 h-10 bg-red-200">
										<ExclamationTriangleIcon
											className="h-5 w-5 stroke-2"
											aria-hidden="true"
										/>
									</div>
									<div className="ml-2 md:ml-4 w-full flex justify-between items-center">
										<p className="text-sm">
											Wil je deze bewoner archiveren
										</p>
										<button
											type="button"
											className="relative flex items-center bg-red-200 text-red-400 rounded-md px-3 h-9 text-sm font-medium"
											onClick={editVisibility}
										>
											Archiveren
										</button>
									</div>
								</div>
							) : (
								<div className="flex relative rounded-lg p-2 flex items-center bg-green-100 text-green-600 max-w-lg">
									<div className="flex items-center justify-center rounded-lg w-10 h-10 bg-green-200">
										<ExclamationTriangleIcon
											className="h-5 w-5 stroke-2"
											aria-hidden="true"
										/>
									</div>
									<div className="ml-2 md:ml-4 w-full flex justify-between items-center">
										<p className="text-sm">
											Zichtbaarheid herstellen
										</p>
										<button
											type="button"
											className="relative flex items-center bg-green-200 text-green-500 rounded-md px-3 h-9 text-sm font-medium"
											onClick={editVisibility}
										>
											Herstellen
										</button>
									</div>
								</div>
							)}
						</>
					</div>
					<div className="col-span-2">
						<Notes resident={resident} />
					</div>
				</div>
			)}
		</>
	);
};

export default EditResident;
