// Libraries
import { onSnapshot } from "firebase/firestore";

// Utils
import { unwrapSnapshot } from "core/utils";

// Stores
import { authStore, authState } from "core/authentication/store";
import { userStore, userState } from "./store";

export class UserListener {
    on(query) {
        authStore.setState({ isUserAuthenticated: true });

        const unsubscribe = onSnapshot(query, (doc) => {
            if (doc.exists()) {
                authStore.setState({
                    isUserLoaded: true,
                });
                userStore.setState({
                    user: unwrapSnapshot(doc),
                    uid: doc.id,
                });
            } else {
                authStore.setState({ isUserNotFound: true });
            }
        });

        this._unsubscribe = () => unsubscribe();
    }
    off() {
        authStore.setState({
            ...authState,
        });

        userStore.setState({
            ...userState,
        });

        this._unsubscribe();
    }
}
