import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Config
import { config } from "./config";

// Initialize application
const app = initializeApp(config);

// Create modules as needed
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
