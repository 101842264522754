// Core
import { updateLocation } from "core/location/actions";

// Icons
import { UserCircleIcon } from "@heroicons/react/24/outline";

// Config
import { types } from "./config";

// Components
import FormTitle from "components/text/form-title";
import EditText from "components/edit/text";
import EditAddress from "components/edit/address";
import EditCategories from "components/edit/categories";
import EditSwitch from "components/edit/switch";

const Details = ({
	id,
	name = "",
	contact = "",
	address = "",
	email = "",
	number = "",
	hours = "",
	avg = false,
	type = [],
	strength = "",
	potency = "",
	alarm = false,
}) => {
	return (
		<>
			<div className="pt-6 mt-6 border-t" />
			<FormTitle
				Icon={UserCircleIcon}
				title="Contactpersoon"
				description="Informatie over het contact persoon"
			/>
			<EditText
				label="Naam locatie:"
				editKey="name"
				editFunction={updateLocation}
				value={name}
				id={id}
			/>
			<EditText
				label="Contactpersoon:"
				editKey="contact"
				editFunction={updateLocation}
				value={contact}
				id={id}
			/>

			<EditAddress
				label="Adres:"
				editKey="address"
				editFunction={updateLocation}
				value={address}
				id={id}
			/>

			<EditText
				label="Mailadres:"
				editKey="email"
				editFunction={updateLocation}
				value={email}
				id={id}
			/>
			<EditText
				label="Telefoon:"
				editKey="number"
				editFunction={updateLocation}
				value={number}
				id={id}
			/>
			<EditText
				label="Openingstijden:"
				editKey="hours"
				editFunction={updateLocation}
				value={hours}
				id={id}
			/>
			<EditSwitch
				label="Avg:"
				editKey="avg"
				editFunction={updateLocation}
				value={avg}
				id={id}
				labelOn="AVG staat aan"
				labelOff="AVG staat uit"
			/>
			<EditSwitch
				label="Alarm:"
				editKey="alarm"
				editFunction={updateLocation}
				labelOn="Alarm staat aan"
				labelOff="Alarm staat uit"
				value={alarm}
				id={id}
			/>
			<div className="mt-6 pt-6 border-t"></div>
			<FormTitle
				Icon={UserCircleIcon}
				title="Over de Locatie"
				description="Informatie die over de locatie gaat."
			/>

			<EditCategories
				label="Type:"
				editKey="type"
				editFunction={updateLocation}
				selected={type}
				id={id}
				list={types}
			/>
			<EditText
				label="Kracht:"
				editKey="strength"
				editFunction={updateLocation}
				value={strength}
				id={id}
			/>
			<EditText
				label="Potentie:"
				editKey="potency"
				editFunction={updateLocation}
				value={potency}
				id={id}
			/>
		</>
	);
};

export default Details;
