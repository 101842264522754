import { useState } from "react";

import Label from "./label";
import Search from "./search";

const EditLabels = ({ selected = [], setSelected, canEdit }) => {
    const [edit, setEdit] = useState(false);

    if (edit) {
        return (
            <Search
                selected={selected}
                setSelected={setSelected}
                setEdit={setEdit}
            />
        );
    }

    return (
        <div className="mb-6 mt-2">
            {selected.length === 0 ? (
                <span className="inline-flex text-xs font-bold">
                    Nog geen label
                </span>
            ) : (
                selected.map((item) => <Label key={item.id} text={item.name} />)
            )}
            {canEdit && (
                <button
                    onClick={() => setEdit(true)}
                    className="font-bold text-xs ml-2 text-green-400"
                >
                    aanpassen
                </button>
            )}
        </div>
    );
};

export default EditLabels;
