export const types = [
    "Fysieke plek",
    "Digitale plek",
    "Instituut",
    "Lokale economie",
    "Vereniging",
    "Buiten",
    "Binnen",
    "Flexibel",
    "Openbaar",
    "Semi-openbaar",
];
