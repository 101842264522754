// Icons
import { UserPlusIcon } from "@heroicons/react/24/outline";

// Utils
import { dateFull } from "components/utils/dates";

const StartLog = ({
    type = "",
    createdAt = new Date(),
    firstName = "User",
}) => {
    return (
        <div className="relative pb-8">
            <div className="relative flex items-start space-x-3">
                <div>
                    <div className="w-10 h-10 rounded-md bg-orange-100 inline-flex items-center justify-center">
                        <UserPlusIcon className="h-5 w-5 text-orange-400 stroke-2" />
                    </div>
                </div>
                <div className="min-w-0 flex-1 py-1.5">
                    <div className="text-sm">
                        {type} toegevoegd door{" "}
                        <span className="font-medium tracking-tight text-xl-tight">
                            {firstName}
                        </span>{" "}
                        <span className="whitespace-nowrap">
                            op {dateFull(createdAt)}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StartLog;
