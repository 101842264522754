import { writeBatch, doc, collection, increment } from "firebase/firestore";

// Db
import { db } from "core/firebase";

// Stores
import { userStore } from "core/user";
import { setUser } from "core/utils";

export const createLocation = async (payload) => {
    const user = setUser(userStore.getState().user);

    const batch = writeBatch(db);

    const refLog = doc(collection(db, "locations"));
    const trimmed = payload.trim().toLowerCase();

    batch.set(refLog, {
        name: payload,
        trimmed: trimmed,
        createdBy: user,
        createdAt: new Date(),
        visibility: true,
    });

    const error = await batch.commit().catch((error) => error);

    if (error) {
        return { error };
    }

    return { id: refLog.id };
};

export const updateLocation = async (id, editKey, payload) => {
    const { user } = userStore.getState();

    const createdBy = setUser(user);

    const batch = writeBatch(db);

    const refNote = doc(collection(db, "locations", id, "notes"));
    const refResident = doc(db, "locations", id);

    batch.update(refResident, {
        updatedAt: new Date(),
        [editKey]: payload,
    });

    batch.set(refNote, {
        content: payload,
        editKey: editKey,
        createdBy,
        type: "update",
        residentId: id,
        createdAt: new Date(),
        visibility: true,
    });

    const error = await batch.commit().catch((error) => error);

    return error;
};

export const createLocationNote = async (payload, id) => {
    const { user } = userStore.getState();

    const createdBy = setUser(user);

    const batch = writeBatch(db);

    const refNote = doc(collection(db, "locations", id, "notes"));
    const refNoteRaw = doc(db, "notes_locations", refNote.id);
    const refResident = doc(db, "locations", id);

    batch.update(refResident, {
        notesSize: increment(1),
    });

    batch.set(refNote, {
        text: payload,
        createdBy,
        type: "note",
        residentId: id,
        createdAt: new Date(),
        visibility: true,
    });

    batch.set(refNoteRaw, {
        text: payload,
        createdBy,
        type: "note",
        residentId: id,
        createdAt: new Date(),
        visibility: true,
    });

    const error = await batch.commit().catch((error) => error);

    return error;
};

export const toggleActivity = async (locationId, activityId, payload) => {
    const batch = writeBatch(db);

    const refLocation = doc(db, "locations", locationId);
    const refActivity = doc(db, "activities", activityId);

    batch.update(refLocation, {
        updatedAt: new Date(),
        [`activities.${activityId}`]: payload,
        activitySize: payload ? increment(1) : increment(-1),
    });

    batch.update(refActivity, {
        updatedAt: new Date(),
        [`locations.${locationId}`]: payload,
        locationSize: payload ? increment(1) : increment(-1),
    });

    const error = await batch.commit().catch((error) => error);

    return error;
};

export const toggleResident = async (locationId, residentId, payload) => {
    const batch = writeBatch(db);

    const refResident = doc(db, "residents", residentId);
    const refLocation = doc(db, "locations", locationId);

    batch.update(refResident, {
        updatedAt: new Date(),
        [`locations.${locationId}`]: payload,
        locationSize: payload ? increment(1) : increment(-1),
    });

    batch.update(refLocation, {
        updatedAt: new Date(),
        [`residents.${residentId}`]: payload,
        residentSize: payload ? increment(1) : increment(-1),
    });

    const error = await batch.commit().catch((error) => error);

    return error;
};
