// Libraries
import { signOut } from "firebase/auth";
import { doc } from "firebase/firestore";

// Firebase
import { auth } from "core/firebase";
import { db } from "core/firebase";

// Listener
import { UserListener } from "./utils";

const userListener = new UserListener();

export const setUserListener = (user) => {
    const query = doc(db, "admins", user.uid);

    userListener.on(query);
};

export const removeUserListener = async () => {
    userListener.off();
    await signOut(auth);
    return;
};
