// Icons
import { CheckIcon } from "@heroicons/react/24/outline";

// Components
import Label from "components/text/label";
import Paragraph from "components/text/paragraph";

const Item = ({
    id,
    firstName,
    lastName,
    createdBy,
    selected = {},
    editId,
    toggleResident,
}) => {
    const active = !!selected[id];

    const onClick = async () => {
        await toggleResident(editId, id, !active);
    };

    return (
        <div
            onClick={onClick}
            className={`relative flex items-center justify-between cursor-pointer rounded-lg px-5 py-4 border-2 focus:outline-none ${
                active && "bg-green-100 border-green-400 text-green-500"
            }`}
        >
            <div>
                <Label text={`${firstName} ${lastName}`} />
                <Paragraph text={`Aangemaakt door ${createdBy.firstName}`} />
            </div>
            {active && (
                <div className="inline-flex items-center justify-center h-7 w-7 rounded-full bg-green-400 text-white">
                    <CheckIcon className="h-5 w-5 stroke-2" />
                </div>
            )}
        </div>
    );
};

export default Item;
