import { useState } from "react";

// Core
import { notificationStore } from "core/notification/store";
import { createNote } from "core/resident/actions";
// Icons
import { PlusCircleIcon } from "@heroicons/react/24/outline";

// Components
import Thumbnail from "components/images/thumbnail";
import TextArea from "components/inputs/textarea";
import Label from "components/text/label";
import Button from "components/button";

const Form = ({ user = null, id }) => {
    const { openNotification } = notificationStore();

    const [note, setNote] = useState("");
    const [loading, setLoading] = useState(false);

    const onSubmitNote = async (event) => {
        event.preventDefault();
        setLoading(true);

        const error = await createNote(note, id);

        setLoading(false);
        if (error) {
            openNotification({
                error: true,
                message: "Fout opgetreden bij het opslaan van de notitie",
            });
        } else {
            setNote("");
            openNotification({
                message: "Notitie opgeslagen",
            });
        }
    };

    return (
        <form className="relative pb-8" onSubmit={onSubmitNote}>
            <span
                className="absolute top-5 left-5 -ml-[2px] h-full w-0.5 bg-gray-200"
                aria-hidden="true"
            />
            <div className="relative flex items-start space-x-3">
                <div className="relative">
                    <Thumbnail user={user} size="h-10 w-10" />
                </div>
                <div className="min-w-0 flex-1">
                    <Label
                        text={`${user?.firstName} ${user?.lastName}`}
                        className="mb-2"
                    />

                    <TextArea
                        rows={2}
                        value={note}
                        onChange={({ target }) => setNote(target.value)}
                        placeholder="Type hier een notitie"
                        className="mb-4"
                    />

                    {!!note.length && (
                        <Button
                            type="submit"
                            text="Notitie aanmaken"
                            loading={loading}
                        >
                            {!loading && (
                                <PlusCircleIcon className="h-5 w-5 stroke-2 ml-2" />
                            )}
                        </Button>
                    )}
                </div>
            </div>
        </form>
    );
};

export default Form;
