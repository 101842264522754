import { RouterProvider } from "react-router-dom";

// Router
import { router } from "./router";

const Authentication = () => {
    return <RouterProvider router={router} />;
};

export default Authentication;
