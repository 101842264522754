const Ping = ({ size = "h-2 w-2", color = "bg-red-400", className = null }) => {
    return (
        <span className={`flex relative mr-2 ${size} ${className}`}>
            <span
                className={`animate-ping absolute inline-flex h-full w-full rounded-full opacity-75 ${color}`}
            />
            <span
                className={`relative inline-flex rounded-full ${size} ${color}`}
            />
        </span>
    );
};

export default Ping;
