// Components
import ListEmpty from "components/list/empty";

const Empty = () => {
    return (
        <ListEmpty
            description=" Er zijn nog geen bewoners aangemaakt, klik op de knop beneden om
                    een bewoner toe te voegen."
            label="Nieuwe bewoner"
            href="/bewoner-starten"
        />
    );
};

export default Empty;
