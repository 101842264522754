// Icons
import { WrenchScrewdriverIcon } from "@heroicons/react/24/outline";

// Components
import Log from "./update.log";
import List from "./update.list";

const Update = ({ list }) => {
    if (!list || list.length === 0) {
        return null;
    }
    if (list.length === 1) {
        const item = list[0];
        return (
            <div className="relative pb-8">
                <span
                    className="absolute top-5 left-5 -ml-[2px] h-full w-0.5 bg-gray-200"
                    aria-hidden="true"
                />
                <div className="relative flex items-start space-x-3">
                    <div>
                        <div className="ml-[2px] w-8 h-8 rounded-md bg-green-100 inline-flex items-center justify-center">
                            <WrenchScrewdriverIcon className="h-5 w-5 text-green-400 stroke-2" />
                        </div>
                    </div>

                    <Log {...item} />
                </div>
            </div>
        );
    }

    return <List list={list} />;
};

export default Update;
