export const priorities = [
    {
        key: "green",
        name: "laag",
    },
    { key: "orange", name: "medium" },
    { key: "red", name: "hoog" },
];

export const points = [
    {
        name: "Succespunten",
        key: "success",
        color: "green",
    },
    { name: "Aandachtspunten", key: "attention", color: "orange" },
    {
        name: "Obstakels",
        key: "obstacles",
        color: "red",
    },
];
