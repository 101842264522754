// Switch
import Switch from "components/inputs/switch";

const EditSwitch = ({
    label,
    value = false,
    id,
    editKey,
    labelOn,
    editFunction,
    labelOff,
}) => {
    const editItem = async () => {
        await editFunction(id, editKey, !value);
    };

    return (
        <div className="grid grid-cols-6 flex items-center py-2">
            <div className="col-span-2">
                <div className="text-sm font-semibold">{label}</div>
            </div>
            <div className="text-sm col-span-4 flex justify-between items-center">
                <Switch
                    active={value}
                    onChange={editItem}
                    labelOn={labelOn}
                    labelOff={labelOff}
                />
            </div>
        </div>
    );
};

export default EditSwitch;
