// Components
import Thumbnail from "components/images/thumbnail";

const User = ({
    user,
    className = "",
    size = "h-6 w-6",
    textSize = "text-sm",
}) => {
    return (
        <div className={`flex items-center ${className}`}>
            <Thumbnail user={user} size={size} />
            <div className={`font-copy  ml-2 ${textSize}`}>
                {user.firstName} {user.lastName}
            </div>
        </div>
    );
};

export default User;
