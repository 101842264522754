import { create } from "zustand";

export const userState = {
    user: null,
    uid: null,
};

export const userStore = create(() => ({
    ...userState,
}));
