import { useState } from "react";

// Icon
import { TrashIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";

// Components
import Button from "components/button/border";
import Input from "components/inputs/input";

const Item = ({ id, text = "", status = null, item, editItem, removeItem }) => {
    const [edit, setEdit] = useState(false);
    const [updatedText, setText] = useState(text);

    if (edit) {
        return (
            <>
                <div className="flex items-center mb-2 mt-2">
                    <Input
                        placeholder={`Type een omschrijving`}
                        value={updatedText}
                        onChange={({ target }) => setText(target.value)}
                    />
                </div>
                <div className="flex items-center">
                    <Button onClick={() => setEdit(false)} className="mr-4">
                        <ArrowLeftIcon className="w-5 h-5 stroke-2" />
                    </Button>
                    <Button
                        text="Opslaan"
                        onClick={() => {
                            editItem({
                                item,
                                id,
                                text: updatedText,
                            });
                            setEdit(false);
                        }}
                        disabled={!text?.length}
                    ></Button>
                    <Button
                        onClick={() => removeItem({ item, id })}
                        className="bg-red-400 text-red-700 ml-4"
                    >
                        <TrashIcon className="w-5 h-5 stroke-2" />
                    </Button>
                </div>
            </>
        );
    }
    return (
        <div
            className="py-2 flex items-center justify-between"
            onClick={() => setEdit(true)}
        >
            <p className="text-sm font-light loading-snug text-gray-500">
                {text}
            </p>
        </div>
    );
};

export default Item;

/*
Cotton candy lemon drops bonbon marzipan biscuit gummi bears oat cake.

Danish caramels danish chocolate bar pudding sugar plum icing.
*/
