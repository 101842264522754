const Label = ({ text = "", color = "bg-green-100 text-green-400" }) => {
	return (
		<div
			className={`px-2 py-[2px] rounded-lg inline-flex text-xs font-medium ${color}`}
		>
			{text}
		</div>
	);
};

export default Label;
