// Icons
import { PlusCircleIcon } from "@heroicons/react/24/outline";

// Components
import Button from "components/button/link";

const ListEmpty = ({
    title = "Deze lijst is leeg",
    description = "",
    href = "",
    label = "Nieuw item starten",
}) => {
    return (
        <div className="flex justify-center">
            <div className="relative block max-w-2xl  w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center mt-12">
                <h1 className="text-3xl font-medium text-gray-900">{title}</h1>
                <p className="mt-1 text-base text-gray-400">{description}</p>
                <div className="flex justify-center mt-6">
                    <Button href={href} size="h-10">
                        <span>{label}</span>
                        <PlusCircleIcon className="h-5 w-5 ml-2 stroke-2" />
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ListEmpty;
