const Switch = ({ active, onChange, labelOn = "Aan", labelOff = "Uit" }) => {
    return (
        <label className="relative inline-flex items-center cursor-pointer">
            <input
                type="checkbox"
                onChange={onChange}
                className="sr-only peer"
                checked={active}
            />
            <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-200 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-400/80"></div>
            <span className="font-copy 2xl:text-md font-light text-sm ml-3">
                {active ? labelOn : labelOff}
            </span>
        </label>
    );
};

export default Switch;
