import { useState } from "react";

// Libraries
import { motion } from "framer-motion";

const BackgroundImage = ({ src = "" }) => {
    const [loaded, toggleLoading] = useState(false);

    return (
        <>
            <div
                className={`absolute top left h-full w-full bg-cover bg-no-repeat bg-center transition-opacity duration-1000 ${
                    loaded ? "opacity-100" : "opacity-0"
                }`}
                style={loaded ? { backgroundImage: `url(${src})` } : null}
            />
            <img
                src={src}
                onLoad={() => toggleLoading(true)}
                alt=""
                className="hidden"
            ></img>
        </>
    );
};

const Thumbnail = ({ user, className = "", size = "h-6 w-6" }) => {
    const [isInView, setIsInView] = useState(false);

    const setInView = () => {
        !isInView && setIsInView(true);

        return {
            scale: 1,
        };
    };

    if (!user) {
        return null;
    }

    if (!user.imagePath) {
        return (
            <motion.div
                initial={{ scale: 0 }}
                whileInView={setInView}
                viewport={{
                    once: true,
                }}
                className={`inline-flex items-center justify-center rounded-md bg-navigation relative overflow-hidden text-bold text-xs ${className} ${size}`}
            >
                {isInView && (
                    <div className="text-green-400">
                        {user.firstName.substring(0, 1)}
                        {user.lastName.substring(0, 1)}
                    </div>
                )}
            </motion.div>
        );
    }

    return (
        <motion.div
            initial={{ scale: 0 }}
            whileInView={setInView}
            viewport={{
                once: true,
            }}
            className={`inline-block rounded-md bg-navigation relative overflow-hidden ${className} ${size}`}
        >
            {isInView && (
                <BackgroundImage
                    src={`${process.env.PUBLIC_URL}/${user.imagePath}`}
                />
            )}
        </motion.div>
    );
};

export default Thumbnail;
