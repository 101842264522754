import { PlusCircleIcon } from "@heroicons/react/24/outline";

// Components
import Link from "components/button/link";
import Input from "components/inputs/input";

const Navigation = ({ search = "", setSearch = () => {} }) => {
    const onChange = ({ target }) => {
        setSearch(target.value);
    };

    return (
        <div className="h-16 flex items-center justify-between">
            <Input
                type="search"
                className="flex-1"
                value={search}
                onChange={onChange}
                placeholder="Zoeken op naam van activiteit..."
            />
            <div>
                <Link href="/activiteit-starten" className="ml-4" size="h-10">
                    <span>Nieuwe activiteit</span>
                    <div>
                        <PlusCircleIcon className="h-5 w-5 stroke-2 ml-2" />
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default Navigation;
