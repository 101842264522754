// Libraries
import { motion } from "framer-motion";

const margin = {
    open: {
        marginLeft: "0.5rem",
        transition: {
            duration: 0.3,
        },
    },
    closed: { marginLeft: "0rem" },
};

const path1 = {
    open: {
        pathLength: 1,
        transition: {
            delay: 0.2,
            duration: 0.6,
        },
    },
    closed: { pathLength: 0 },
};

const path = {
    open: {
        pathLength: 0.55,
        transition: {
            delay: 0.2,
            duration: 1,
            // repeatType: "reverse",
            // repeat: Infinity,
            // repeatDelay: 0.2,
        },
    },
    closed: { pathLength: 0 },
};

const Button = ({
    type = "button",
    children = null,
    text = "",
    className = "",
    loading = false,
    disabled = false,
    size = "h-9",
    onClick = () => {},
}) => {
    // const whiteHover = () => {
    //     if (disabled || loading) {
    //         return {
    //             backgroundColor: "rgb(138, 151, 211)",
    //         };
    //     }
    //     return {
    //         scale: 1.1,
    //     };
    // };

    return (
        <motion.button
            type={type}
            disabled={disabled || loading}
            className={`relative flex items-center rounded-md px-3 text-sm font-semibold 
            ${className}
            ${size}
            ${
                disabled
                    ? "bg-gray-100 text-gray-300 cursor-not-allowed"
                    : "bg-green-400 text-white"
            }
            ${loading && "btn-loading"}`}
            onClick={onClick}
        >
            <div className="relative flex items-center z-10">
                {text}
                {children}

                <motion.svg
                    initial={false}
                    animate={loading ? "open" : "closed"}
                    variants={margin}
                    className={`${
                        loading ? "h-5 w-5 animate-spin" : "h-0 w-0"
                    }`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <motion.circle
                        initial={false}
                        animate={loading ? "open" : "closed"}
                        variants={path1}
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                    ></motion.circle>
                    <motion.circle
                        initial={false}
                        animate={loading ? "open" : "closed"}
                        variants={path}
                        className="opacity-75"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                    ></motion.circle>
                </motion.svg>
            </div>
        </motion.button>
    );
};

export default Button;
