import { useState } from "react";

// Icon
import { PencilSquareIcon, XCircleIcon } from "@heroicons/react/24/outline";

// Components
import Select from "components/inputs/select";
import Button from "components/button/border";

const EditSelect = ({ label, value, id, editKey, editFunction, list }) => {
    const [edit, setEdit] = useState(false);

    const openEdit = () => {
        setEdit(true);
    };

    const editSelect = async (payload) => {
        await editFunction(id, editKey, payload);
        setEdit(false);
    };

    if (edit) {
        return (
            <div className="py-2">
                <div className="text-sm font-semibold mb-2">{label}</div>
                <div className="flex">
                    <Select
                        list={list}
                        setSelected={editSelect}
                        selected={value}
                    />
                    <Button
                        onClick={() => setEdit(false)}
                        className="ml-4"
                        height="h-10"
                    >
                        <XCircleIcon className="w-5 h-5 stroke-2" />
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <div className="grid grid-cols-6 flex items-center py-2">
            <div className="col-span-2">
                <div className="text-sm font-semibold">{label}</div>
            </div>
            <div className="text-sm col-span-4 font-light flex justify-between items-center">
                <span>{value}</span>

                <button className="ml-4 p-2" type="button" onClick={openEdit}>
                    <PencilSquareIcon className="h-5 w-5 stroke-2 text-gray-600" />
                </button>
            </div>
        </div>
    );
};

export default EditSelect;
