const Label = ({ text = "", active = true, margen = "mt-2" }) => {
	return (
		<div
			className={`px-2 py-[1px] border-2 rounded-lg  mr-2 inline-flex text-xs font-medium ${margen} ${
				active
					? "bg-green-400 border-green-400 text-green-700"
					: "border-gray-200 text-gray-400"
			}`}
		>
			{text}
		</div>
	);
};

export default Label;
