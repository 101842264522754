export const themesList = [
    "Eenzaamheid",
    "Veiligheid",
    "Kinderactiviteit",
    "Jongerenactiviteit",
    "Groen",
    "Duurzaamheid",
    "Sport en spel",
    "Gezond en voeding",
    "Persoonlijke ontwikkeling",
    "Creatief",
    "Sociaal",
];

export const agesList = [
    "17 of jonger",
    "18-20",
    "21-29",
    "30-39",
    "40-49",
    "50-59",
    "60-69",
    "70 of ouder",
];

export const rolesList = [
    "Communitybuilder",
    "Verbinder",
    "Informele leider",
    "Ideeëndrager",
    "Gever",
    "Meedoener",
];
