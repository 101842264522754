import { useState } from "react";

// Icons
import { PlusCircleIcon, MinusCircleIcon } from "@heroicons/react/24/outline";

// Import
import Button from "components/button";
import Label from "components/inputs/label";
import Input from "components/inputs/input";

import Item from "./item";

const List = ({ list, item, addItem, removeItem, editItem, canEdit }) => {
	const [openCreate, setOpenCreate] = useState(false);
	const [text, setText] = useState("");

	const submitToList = () => {
		addItem({
			key: item.key,

			text,
		});

		setText("");
		setOpenCreate(false);
	};

	return (
		<div key={item.keys} className="py-4 sm:py-5 border-t">
			<div className="flex justify-between items-center">
				<div className="flex items-center">
					<div
						className={`h-4 w-4 rounded mr-2 bg-${item.color}-400`}
					/>
					<Label text={`${item.name}:`} />
				</div>
				{canEdit && (
					<button
						type="button"
						className="pl-2 pt-1 pb-1 text-gray-600"
						onClick={() => setOpenCreate(!openCreate)}
					>
						{openCreate ? (
							<MinusCircleIcon className="w-5 h-5 stroke-2" />
						) : (
							<PlusCircleIcon className="w-5 h-5 stroke-2" />
						)}
					</button>
				)}
			</div>
			{openCreate && (
				<div>
					<div className="flex items-center mb-2 mt-2">
						<Input
							placeholder="Type een omschrijving"
							value={text}
							onChange={({ target }) => setText(target.value)}
						/>
					</div>
					<Button
						text="Toevoegen"
						onClick={submitToList}
						disabled={!text.length}
					>
						<PlusCircleIcon className="w-5 h-5 stroke-2 ml-2" />
					</Button>
				</div>
			)}
			<div className="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">
				{list.map((payload) => (
					<Item
						key={payload.id}
						{...payload}
						item={item}
						removeItem={removeItem}
						editItem={editItem}
						canEdit={canEdit}
					/>
				))}
			</div>
		</div>
	);
};

export default List;
