import { IconLoading } from "components/icons";

const ListLoading = () => {
    return (
        <div className="flex justify-center items-center py-12 mt-12">
            <IconLoading className="h-12 w-12 text-green-400" />
        </div>
    );
};

export default ListLoading;
