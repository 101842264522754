import { useState } from "react";

// Libraries
import { MarkerF, InfoWindowF } from "@react-google-maps/api";

// Components
import InfoWindow from "./info-window";

const Marker = ({ item, type }) => {
	const [open, setInfoWindow] = useState(false);

	if (!item.address.lat || !item.address.lng) {
		return null;
	}

	const openInfoWindow = () => {
		setInfoWindow(true);
	};

	const closeInfoWindow = () => {
		setInfoWindow(false);
	};

	return (
		<MarkerF
			position={{ lat: item.address.lat, lng: item.address.lng }}
			onClick={openInfoWindow}
			classname="text-green-400"
			icon={process.env.PUBLIC_URL + `/marker/${type}.svg`}
		>
			{open && (
				<InfoWindowF>
					<InfoWindow
						closeInfoWindow={closeInfoWindow}
						item={item}
						type={type}
					/>
				</InfoWindowF>
			)}
		</MarkerF>
	);
};

export default Marker;
