export const Light = ({ active }) => {
    return (
        <div
            className={`mt-1 inline w-4 h-4 border-4 rounded-full ${
                active
                    ? "bg-green-300 border-green-400"
                    : "bg-red-300 border-red-400"
            }`}
        ></div>
    );
};
