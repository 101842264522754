// Utils
import { dateFull } from "components/utils/dates";

// Config
import { statuses } from "pages/dashboard/activities-edit/config";

// Components
import User from "components/utils/user";
import Link from "components/button/link";
import Ping from "components/utils/ping";
import Label from "components/labels/label-note";

const Item = ({
	id,
	name,
	createdAt,
	createdBy,
	updatedAt = null,
	notesSize = 0,
	residentSize = 0,
	alarm = false,
	status = null,
}) => {
	return (
		<div className="grid grid-cols-6 gap-2 lg:grid-cols-10 lg:gap-4 py-2 flex items-center border-t">
			<div className="col-span-2 text-xs inline-flex items-center  overflow-hidden">
				{alarm && <Ping className="mr-2" />}
				<Label
					text={status || "Geen status"}
					color={`
									${!status && "bg-gray-200"}
									${status === statuses[0] && "bg-yellow-400"} 
									${status === statuses[1] && "bg-green-400"}
									${status === statuses[2] && "bg-orange-400"}
									${status === statuses[3] && "bg-blue-400"}`}
				/>
				<div className="mr-2"></div>
				{dateFull(createdAt)}
			</div>
			<div className="col-span-2 text-xs text-ellipsis overflow-hidden">
				{name}
			</div>
			<div className="hidden lg:inline-flex lg:col-span-2">
				<User user={createdBy} textSize="text-xs" />
			</div>
			<div className="hidden lg:inline-flex lg:col-span-1 text-xs">
				{notesSize}
			</div>
			<div className="hidden lg:inline-flex lg:col-span-1 text-xs">
				{residentSize}
			</div>
			<div className="hidden lg:inline-flex lg:col-span-1 text-xs">
				{updatedAt ? dateFull(updatedAt) : ""}
			</div>

			<div className="col-span-1">
				<Link c href={`/activiteit/${id}`}>
					<span>Bekijk</span>
				</Link>
			</div>
		</div>
	);
};

export default Item;
