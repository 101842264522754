// Components
import { IconLoading } from "components/icons";

const PageLoading = () => {
    return (
        <div className="h-screen w-full flex justify-center items-center">
            <div>
                <IconLoading className="h-20 w-20 text-green-400" />
            </div>
        </div>
    );
};

export default PageLoading;
