import { useState } from "react";

// Core
import { toggleActivity, toggleLocation } from "core/resident/actions";

// Icons
import { LinkIcon } from "@heroicons/react/24/outline";

// Components
import Button from "components/button/relation";
import FormActivity from "components/forms/form-add-activity";
import FormLocation from "components/forms/form-add-location";
import FormTitle from "components/text/form-title";

const EditRelations = ({
	id,
	locations = {},
	activities = {},
	activitySize = 0,
	locationSize = 0,
}) => {
	const [openActivity, setActivity] = useState(false);
	const [openLocation, setLocation] = useState(false);

	const onCloseActivity = () => {
		setActivity(false);
	};

	const onOpenActivity = () => {
		setActivity(true);
	};

	const onOpenLocation = () => {
		setLocation(true);
	};

	const opCloseLocation = () => {
		setLocation(false);
	};

	return (
		<div className="mt-6 pt-6 border-t">
			<FormTitle
				Icon={LinkIcon}
				title="Maak een verbinding"
				description="Koppel de bewoner hier aan activiteiten en/of locaties."
			/>
			<div className="grid grid-cols-2 gap-4 pt-4">
				<Button
					title="Activiteit"
					description="Voeg een Activiteit toe"
					onClick={onOpenActivity}
					size={activitySize}
				/>
				<Button
					title="Locatie"
					description="Voeg een locatie toe"
					onClick={onOpenLocation}
					size={locationSize}
				/>
			</div>
			<FormActivity
				open={openActivity}
				onClose={onCloseActivity}
				selected={activities}
				editId={id}
				toggleActivity={toggleActivity}
			/>
			<FormLocation
				open={openLocation}
				onClose={opCloseLocation}
				selected={locations}
				editId={id}
				toggleLocation={toggleLocation}
			/>
		</div>
	);
};

export default EditRelations;
