import { useState, useEffect } from "react";

// Core
import {
	collection,
	query,
	onSnapshot,
	orderBy,
	where,
} from "firebase/firestore";
import { db } from "core/firebase";
import { unwrapList } from "core/utils";

// Components
import Navigation from "components/navigation/residents";
import Loading from "components/list/loading";
import ListEmpty from "components/list/residents/empty";
import List from "components/list/residents/list";

const Residents = () => {
	const [list, setList] = useState(null);
	const [searchList, setSearchList] = useState([]);
	const [search, setSearch] = useState("");
	const [selected, setSelected] = useState("Naam");
	const [tag, setTag] = useState(null);

	useEffect(() => {
		const q = query(
			collection(db, "residents"),
			where("visibility", "==", true),
			orderBy("createdAt", "desc")
		);

		const unsubscribe = onSnapshot(q, (payload) => {
			setList(unwrapList(payload));
		});

		return () => unsubscribe();
	}, []);

	useEffect(() => {
		if (!list) return;

		if (!search || !search.length) {
			setSearchList(list);
		} else {
			let result = [];

			if (selected === "Naam") {
				result = list.filter(({ firstName = "", lastName = "" }) =>
					`${firstName} ${lastName}`
						.toLowerCase()
						.includes(search.toLowerCase())
				);
			}

			if (selected === "Idee") {
				result = list.filter(({ ideas = false }) => {
					if (!ideas || !ideas.length) return false;

					let string = [];

					ideas.map(({ text }) => (string += text));

					return string.toLowerCase().includes(search.toLowerCase());
				});
			}

			if (selected === "Talent") {
				result = list.filter(({ types = false }) => {
					if (!types || !types.length) return false;

					let string = [];

					types.map(({ text }) => (string += text));

					return string.toLowerCase().includes(search.toLowerCase());
				});
			}

			setSearchList(result);
		}
	}, [search, list, selected]);

	useEffect(() => {
		if (!list) return;

		if (!tag) {
			setSearchList(list);
		}

		if (tag) {
			if (tag === "Verwijderen") {
				setTag(null);
			}
			const result = list.filter(({ themes = false }) => {
				if (!themes || !themes.length) return false;

				let string = [];

				themes.map((item) => (string += item));

				return string.toLowerCase().includes(tag.toLowerCase());
			});

			setSearchList(result);
		}
	}, [tag, list]);

	return (
		<>
			<Navigation
				search={search}
				setSearch={setSearch}
				selected={selected}
				setSelected={setSelected}
				tag={tag}
				setTag={setTag}
			/>
			{list === null && <Loading />}
			{list && searchList?.length === 0 && <ListEmpty />}
			{searchList?.length > 0 && <List list={searchList} />}
		</>
	);
};

export default Residents;
