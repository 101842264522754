export const routes = {
    ROOT: "/",
    SIGN_IN: "/sign-in",
    RESET: "/reset",
    REGISTER: "/aanmelden",
};

const errorMessages = {
    NAME: "Oeps... er is geen naam ingevoerd.",
    NAME_CHARS:
        "Oeps... het is niet mogelijk om speciale karakters te gebruiken.",

    EMAIL: "Oeps... het ingevoerde emailadres bestaat niet.",
    EMAIL_IN_USE: "Oeps... het ingevoerde emailadres is al in gebruik.",

    PWD_WRONG: "Oeps... er is een onjuist wachtwoord ingevoerd.",
    PWD_WEAK: "Oeps... uw wachtwoord is te zwak. Maak het wachtwoord sterker.",

    USER_DISABLED: "Oeps... deze account is geblokkeerd.",
    USER_NOT_FOUND: "Oeps... deze account is niet gevonden.",

    DEFAULT: "Oeps... er is een onbekende fout opgetreden.",
};

export const errorCodes = {
    NAME: "auth/name",
    NAME_CHARS: "auth/name-chars",

    PWD_WRONG: "auth/wrong-password",
    PWD_WEAK: "auth/weak-password",

    EMAIL: "auth/invalid-email",
    EMAIL_IN_USE: "auth/email-already-in-use",

    USER_DISABLED: "auth/user-disabled",
    USER_NOT_FOUND: "auth/user-not-found",
};

export const errorMessage = (code) => {
    if (!code) return;

    switch (code) {
        case errorCodes.NAME:
        case errorCodes.NAME_CHARS:
            return errorMessages.NAME;

        case errorCodes.PWD_WRONG:
            return errorMessages.PWD_WRONG;

        case errorCodes.PWD_WEAK:
            return errorMessages.PWD_WEAK;

        case errorCodes.EMAIL:
            return errorMessages.EMAIL;

        case errorCodes.EMAIL_IN_USE:
            return errorMessages.EMAIL_IN_USE;

        case errorCodes.USER_DISABLED:
            return errorMessages.USER_DISABLED;

        case errorCodes.USER_NOT_FOUND:
            return errorMessages.USER_NOT_FOUND;

        default:
            return errorMessages.DEFAULT;
    }
};

export const titles = {
    AUTH_WELCOME: "Welkom bij Youlab",
    AUTH_RESET: "Wachtwoord herstellen",
    AUTH_SIGN_IN: "Aanmelden bij Youlab",
    AUTH_REGISTER: "Registreren bij Youlab",
};
