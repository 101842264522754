import { Link } from "react-router-dom";

const NavLink = ({
    children = null,
    href = "",
    className = "",
    size = "h-8",
}) => {
    return (
        <Link
            to={href}
            className={`relative inline-flex items-center bg-green-400/40 text-green-500 rounded-md px-3 text-sm font-medium focus:outline-none ${size} ${className}`}
        >
            {children}
        </Link>
    );
};

export default NavLink;
