import { useState } from "react";

// Library
import { useNavigate } from "react-router-dom";

// Icon
import { PlusCircleIcon } from "@heroicons/react/24/outline";

// Core
import { createResident } from "core/resident/actions";
import { notificationStore } from "core/notification/store";

// Components
import Navigation from "components/navigation/resident-create";
import Label from "components/inputs/label";
import Input from "components/inputs/input";
import Button from "components/button";

const CreateResident = () => {
    const navigate = useNavigate();

    // Store
    const { openNotification } = notificationStore();

    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");

    const onSubmitResident = async () => {
        setLoading(true);

        const { id = false } = await createResident({
            firstName,
            lastName,
        });

        if (!id) {
            openNotification({
                error: true,
                message: "Fout bij het aanmaken van de bewoner",
            });
        } else {
            openNotification({
                error: false,
                message: "Bewoner succesvol aangemaakt",
            });
            navigate(`/bewoner/${id}`);
        }
    };

    return (
        <>
            <Navigation />
            <div className="p-6 rounded-md border-2 mb-6">
                <div className="grid sm:grid-cols-2 sm:gap-10 pb-6 pt-6">
                    <div>
                        <Label text="Voer een voornaam in:" className="mb-1" />
                        <Input
                            placeholder="Type hier een voornaam"
                            value={firstName}
                            onChange={({ target }) =>
                                setFirstName(target.value)
                            }
                        />
                    </div>
                    <div>
                        <Label
                            text="Voer een achternaam in:"
                            className="mb-1"
                        />
                        <Input
                            placeholder="Type hier een achternaam"
                            value={lastName}
                            onChange={({ target }) => setLastName(target.value)}
                        />
                    </div>
                </div>
                <div className="border-t-2 pt-6">
                    <Button
                        text="Opslaan"
                        onClick={onSubmitResident}
                        loading={loading}
                        disabled={
                            loading || !firstName?.length || !lastName?.length
                        }
                    >
                        {!loading && (
                            <PlusCircleIcon className="h-5 w-5 stroke-2 ml-2" />
                        )}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default CreateResident;
