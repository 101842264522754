import { useRef, useEffect } from "react";

// Libaries
import { NavLink } from "react-router-dom";

const useOutsideClick = (ref, callback) => {
	const handleClick = (e) => {
		if (ref.current && !ref.current.contains(e.target)) {
			callback();
		}
	};

	useEffect(() => {
		document.addEventListener("mouseup", handleClick);

		return () => {
			document.removeEventListener("mouseup", handleClick);
		};
	});
};

const InfoWindow = ({ item, type, closeInfoWindow }) => {
	const ref = useRef();

	useOutsideClick(ref, closeInfoWindow);

	return (
		<div ref={ref} className="w-48 p-2">
			<div className="font-semibold text-base leading-snug mb-2 text-center">
				{type === "activity" && item?.name}
				{type === "resident" && `${item?.firstName} ${item?.lastName}`}
				{type === "location" && `${item?.name}`}
			</div>
			{type === "activity" && (
				<NavLink
					to={`/activiteit/${item.id}`}
					className="flex font-semibold justify-center items-center px-4 h-9 rounded-md bg-green-400 transition-all truncate text-white w-full"
				>
					Bekijk activiteit
				</NavLink>
			)}
			{type === "resident" && (
				<NavLink
					to={`/bewoner/${item.id}`}
					className="flex font-semibold justify-center items-center px-4 h-9 rounded-md bg-green-400 transition-all truncate text-white w-full"
				>
					Bekijk bewoner
				</NavLink>
			)}
			{type === "location" && (
				<NavLink
					to={`/locatie/${item.id}`}
					className="flex font-semibold justify-center items-center px-4 h-9 rounded-md bg-green-400 transition-all truncate text-white w-full"
				>
					Locatie
				</NavLink>
			)}
		</div>
	);
};

export default InfoWindow;
