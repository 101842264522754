import { createBrowserRouter, Navigate } from "react-router-dom";

// Pages
import Root from "./root";
import Login from "./login";
import Reset from "./reset";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <Navigate to="/" replace />,
        children: [
            {
                path: "",
                element: <Login />,
            },
            {
                path: "reset",
                element: <Reset />,
            },
        ],
    },
]);

export { router };
