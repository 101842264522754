import { useState } from "react";

// Library
import { useNavigate } from "react-router-dom";

// Icon
import { PlusCircleIcon } from "@heroicons/react/24/outline";

// Core
import { createLocation } from "core/location/actions";
import { notificationStore } from "core/notification/store";

// Components
import Navigation from "components/navigation/locations-create";
import Label from "components/inputs/label";
import Input from "components/inputs/input";
import Button from "components/button";

const CreateLocation = () => {
	const navigate = useNavigate();

	// Store
	const { openNotification } = notificationStore();

	const [loading, setLoading] = useState(false);
	const [name, setName] = useState("");

	const onSubmitResident = async () => {
		setLoading(true);

		const { id = false } = await createLocation(name);

		if (!id) {
			openNotification({
				error: true,
				message: "Fout bij het aanmaken van de bewoner",
			});
		} else {
			openNotification({
				error: false,
				message: "Bewoner succesvol aangemaakt",
			});

			navigate(`/locatie/${id}`);
		}
	};

	return (
		<>
			<Navigation />
			<div className="p-6 rounded-md border-2 mb-6 mt-2">
				<div className="pb-6 pt-6 max-w-xl">
					<Label text="Voer een naam in:" className="mb-1" />
					<Input
						placeholder="Type hier een naam"
						value={name}
						onChange={({ target }) => setName(target.value)}
					/>
				</div>
				<div className="border-t-2 pt-6">
					<Button
						text="Opslaan"
						onClick={onSubmitResident}
						loading={loading}
						disabled={!name.length}
					>
						{!loading && (
							<PlusCircleIcon className="h-5 w-5 stroke-2 ml-2" />
						)}
					</Button>
				</div>
			</div>
		</>
	);
};

export default CreateLocation;
