import { PlusCircleIcon } from "@heroicons/react/24/outline";

// Components
import Link from "components/button/link";
import Input from "components/inputs/input";
import Select from "components/inputs/select-author";
const Navigation = ({
	search = "",
	setSearch = () => {},
	users,
	selected = undefined,
	setSelected = () => {},
}) => {
	const onChange = ({ target }) => {
		setSearch(target.value);
	};

	return (
		<div className="h-16 flex items-center justify-between">
			<Input
				type="search"
				className="flex-1"
				value={search}
				onChange={onChange}
				placeholder="Zoeken op naam van Log..."
			/>
			<div className="ml-2">
				<Select
					list={users}
					selected={selected}
					setSelected={setSelected}
				/>
			</div>
			<Link href="/log-starten" className="ml-4" size="h-10">
				<span>Nieuw log</span>
				<div>
					<PlusCircleIcon className="h-5 w-5 stroke-2 ml-2" />
				</div>
			</Link>
		</div>
	);
};

export default Navigation;
