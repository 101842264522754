import { PlusCircleIcon } from "@heroicons/react/24/outline";

// Config
import { themesList } from "pages/dashboard/resident-edit/config";

// Components
import Link from "components/button/link";
import Input from "components/inputs/input";
import SelectSearch from "components/inputs/select-search";

const Navigation = ({
	search = "",
	setSearch = () => {},
	selected = "Naam",
	setSelected = () => {},
	tag,
	setTag,
}) => {
	const onChange = ({ target }) => {
		setSearch(target.value);
	};

	return (
		<div className="h-16 flex items-center justify-between">
			<Input
				type="search"
				className="flex-1 mr-4"
				value={search}
				onChange={onChange}
				placeholder={`Zoeken op ${selected}`}
			/>

			<div className="flex items-center">
				<SelectSearch
					list={["Naam", "Idee", "Talent"]}
					selected={selected}
					setSelected={setSelected}
				/>
				<div className="ml-4"></div>
				<SelectSearch
					list={[...themesList, "Verwijderen"]}
					selected={tag}
					setSelected={setTag}
				/>
				<Link href="/bewoner-starten" className=" ml-4" size="h-10">
					<span>Nieuwe bewoner</span>
					<div>
						<PlusCircleIcon className="h-5 w-5 stroke-2 ml-2" />
					</div>
				</Link>
			</div>
		</div>
	);
};

export default Navigation;
