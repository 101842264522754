// Config
import { updateActivity } from "core/activity/actions";

// Icons
import { UserCircleIcon } from "@heroicons/react/24/outline";

// Config
import {
	themes,
	frequencies,
	moments,
	scales,
	sizes,
	statuses,
} from "./config";

// Components
import FormTitle from "components/text/form-title";
import EditText from "components/edit/text";
import EditAddress from "components/edit/address";
import EditCategories from "components/edit/categories";
import EditSelect from "components/edit/select";
import EditSwitch from "components/edit/switch";

const Details = ({
	id,
	name = "",
	address = "",
	theme = [],
	initiator = null,
	forWho = "",
	sinceWhen = "",
	frequency = "",
	status = "In ontwikkeling",
	moment = "",
	scale = "",
	size = "",
	emptyChair = "",
	alarm = false,
}) => {
	return (
		<>
			<div className="pt-6 mt-6 border-t" />
			<FormTitle
				Icon={UserCircleIcon}
				title="Over de activiteit"
				description="Informatie over waar de activiteit over gaat, wanneer deze is en waar het zich afspeelt."
			/>
			<EditText
				label="Naam activiteit:"
				editKey="name"
				editFunction={updateActivity}
				value={name}
				id={id}
			/>
			<EditSwitch
				label="Alarm:"
				editKey="alarm"
				editFunction={updateActivity}
				labelOn="Alarm staat aan"
				labelOff="Alarm staat uit"
				value={alarm}
				id={id}
			/>
			<EditAddress
				label="Adres:"
				editKey="address"
				editFunction={updateActivity}
				value={address}
				id={id}
			/>
			<EditCategories
				label="Thema:"
				editKey="theme"
				editFunction={updateActivity}
				selected={theme}
				id={id}
				list={themes}
			/>
			<EditText
				label="Initiator:"
				editKey="initiator"
				editFunction={updateActivity}
				value={initiator}
				id={id}
			/>

			<EditText
				label="Voor wie:"
				editKey="forWho"
				editFunction={updateActivity}
				value={forWho}
				id={id}
			/>
			<EditText
				label="Sinds wanneer:"
				editKey="sinceWhen"
				editFunction={updateActivity}
				value={sinceWhen}
				id={id}
			/>
			<EditSelect
				label="Status:"
				editKey="status"
				editFunction={updateActivity}
				value={status}
				id={id}
				list={statuses}
			/>
			<EditSelect
				label="Frequentie:"
				editKey="frequency"
				editFunction={updateActivity}
				value={frequency}
				id={id}
				list={frequencies}
			/>
			<EditSelect
				label="Moment van de dag:"
				editKey="moment"
				editFunction={updateActivity}
				value={moment}
				id={id}
				list={moments}
			/>
			<EditSelect
				label="Schaalgrootte:"
				editKey="scale"
				editFunction={updateActivity}
				value={scale}
				id={id}
				list={scales}
			/>
			<EditSelect
				label="Omvang deelnemers:"
				editKey="size"
				editFunction={updateActivity}
				value={size}
				id={id}
				list={sizes}
			/>
			<EditText
				label="Lege stoel:"
				editKey="emptyChair"
				editFunction={updateActivity}
				value={emptyChair}
				id={id}
			/>
		</>
	);
};

export default Details;
