import { useState, useEffect } from "react";

// Core
import {
	collection,
	query,
	onSnapshot,
	orderBy,
	where,
} from "firebase/firestore";
import { db } from "core/firebase";
import { unwrapList } from "core/utils";

// Icons
import { MapIcon } from "@heroicons/react/24/outline";

// Components
import Modal from "components/forms/modal";
import Form from "./form";
import Title from "components/text/form-title";
import Loading from "components/list/loading";
import ListEmpty from "components/forms/list-empty";
import List from "./list";

const FormLabel = ({
	open = false,
	onClose,
	selected,
	editId,
	toggleLocation,
}) => {
	const [search, setSearch] = useState("");
	const [list, setList] = useState(null);
	const [searchList, setSearchList] = useState([]);

	useEffect(() => {
		const q = query(
			collection(db, "locations"),
			where("visibility", "==", true),
			orderBy("createdAt", "desc")
		);

		const unsubscribe = onSnapshot(q, (payload) =>
			setList(unwrapList(payload))
		);

		return () => unsubscribe();
	}, []);

	useEffect(() => {
		if (!search || !search.length) {
			setSearchList(list);
		} else {
			const result = list.filter(({ name = "" }) =>
				name.toLowerCase().includes(search.toLowerCase())
			);
			setSearchList(result);
		}
	}, [search, list]);

	return (
		<Modal open={open} onClose={onClose}>
			<Title
				title="Selecteer een locatie of maak een nieuwe locatie aan"
				description="Voeg je een nieuwe locatie toe dan is dit direct zichtbaar in het overzicht. Vanuit daar kan aanvullende informatie worden toegevoegd."
				Icon={MapIcon}
			/>
			<Form search={search} setSearch={setSearch} />
			{list === null && <Loading />}
			{list && searchList?.length === 0 && (
				<ListEmpty description=" Er zijn nog geen locaties gevonden" />
			)}
			{searchList?.length > 0 && (
				<List
					list={searchList}
					selected={selected}
					editId={editId}
					toggleLocation={toggleLocation}
				/>
			)}
		</Modal>
	);
};

export default FormLabel;
