// Config
import { types } from "./config";

// Libraries
import { v4 } from "uuid";

// Utils
import { createDateId } from "components/utils/dates";

// Components
import Note from "./note";
import Update from "./update";

const List = ({ list }) => {
    let restart = true;
    let currentListId = null;
    let currentDateId = null;

    const finalList = list.reduce((acc, current) => {
        // if the current === type note
        // add the item to the current list (acc)
        if (current.type === types.note) {
            restart = true;
            return [...acc, current];
        }

        // if the item is === to type update
        // initiate checks to create sub lists
        if (current.type === types.update) {
            // Set the dateId for the current item
            const dateId = createDateId(current.createdAt);

            if (restart) {
                // create a uid
                currentListId = v4();
                currentDateId = dateId;
                restart = false;
                return [
                    ...acc,
                    {
                        id: currentListId,
                        dateId: dateId,
                        type: "list",
                        list: [current],
                    },
                ];
            }

            // Check if the day is the same, if not
            // start a new list
            if (dateId !== currentDateId) {
                currentListId = v4();
                currentDateId = dateId;
                restart = false;
                return [
                    ...acc,
                    {
                        id: currentListId,
                        dateId: dateId,
                        type: "list",
                        list: [current],
                    },
                ];
            }

            const currentListItem = acc.find(
                (item) => item.id === currentListId
            );
            const filteredList = acc.filter(
                (item) => item.id !== currentListId
            );
            return [
                ...filteredList,
                {
                    ...currentListItem,
                    list: [...currentListItem.list, current],
                },
            ];
        }

        return [...acc];
    }, []);

    return finalList.map((item) => {
        if (item.type === types.note) {
            return <Note key={item.id} {...item} />;
        }

        if (item.type === types.list) {
            return <Update key={item.id} {...item} />;
        }

        return null;
    });
};

export default List;
