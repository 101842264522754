import { Fragment, useEffect, useRef } from "react";

// Libraries
import { Transition } from "@headlessui/react";

// Core
import { notificationStore } from "core/notification/store";

// Icons
import {
    CheckCircleIcon,
    ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";

import { XMarkIcon } from "@heroicons/react/20/solid";

export default function Example() {
    const { active, error, message, closeNotification } = notificationStore();
    const timer = useRef(null);

    useEffect(() => {
        if (active) {
            timer.current = setTimeout(() => closeNotification(), 3000);
        }

        if (!active) {
            clearTimeout(timer.current);
        }

        // eslint-disable-next-line
    }, [active]);

    return (
        <>
            {/* Global notification live region, render this permanently at the end of the document */}
            <div className="pointer-events-none fixed inset-0 flex items-end px-4 py-4 sm:items-start sm:p-6 z-40">
                <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                    {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                    <Transition
                        show={active}
                        as={Fragment}
                        enter="transform ease-out duration-300 transition"
                        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-md bg-navigation shadow-lg">
                            <div className="p-4">
                                <div className="flex items-start">
                                    <div className="flex-shrink-0">
                                        {error ? (
                                            <ExclamationTriangleIcon className="h-6 w-6 text-red-400 stroke-2" />
                                        ) : (
                                            <CheckCircleIcon className="h-6 w-6 text-green-400 stroke-2" />
                                        )}
                                    </div>
                                    <div className="ml-3 w-0 flex-1">
                                        <p className="text-sm font-medium text-white">
                                            {error
                                                ? "Fout bij het opslaan"
                                                : "Succesvol opgeslagen!"}
                                        </p>
                                        <p className="mt-1 text-sm text-white/60">
                                            {message}
                                        </p>
                                    </div>
                                    <div className="ml-4 flex flex-shrink-0">
                                        <button
                                            type="button"
                                            className="inline-flex rounded-md bg-white/10 text-white hover:text-white/80 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-2"
                                            onClick={closeNotification}
                                        >
                                            <span className="sr-only">
                                                Close
                                            </span>
                                            <XMarkIcon
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        </>
    );
}
