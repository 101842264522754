import { useState, useEffect, useRef } from "react";

// Libraries
import { useParams } from "react-router-dom";
import { v4 } from "uuid";

// Core
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "core/firebase";
import { unwrapSnapshot } from "core/utils";
import { updateActivity } from "core/activity/actions";

// Config
import { statuses, points } from "pages/dashboard/activities-edit/config";

// Icons
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

// Components
import Loading from "components/list/loading";
import Navigation from "components/navigation/activities-edit";
import Meta from "components/edit/meta";
import Notes from "./notes";
import Relations from "./edit-relations";
import Details from "./details";
import Ping from "components/utils/ping";
import Label from "components/labels/label-note";
import List from "pages/dashboard/logbook-edit/list";

const EditActivity = () => {
	const { id } = useParams();
	const [activity, setActivity] = useState(null);

	const ref = useRef(doc(db, "activities", id));

	useEffect(() => {
		const unsubscribe = onSnapshot(ref.current, (payload) =>
			setActivity(unwrapSnapshot(payload))
		);

		return () => unsubscribe();
	}, [id]);

	const addItem = async ({ key, text }) => {
		await updateActivity(
			activity.id,
			key,
			activity[key]
				? [{ text, id: v4() }, ...activity[key]]
				: [{ text, id: v4() }]
		);
	};

	const removeItem = async ({ item, id }) => {
		await updateActivity(
			activity.id,
			item.key,
			activity[item.key].filter((payload) => payload.id !== id)
		);
	};

	const editItem = async ({ item, id, text }) => {
		await updateActivity(
			activity.id,
			item.key,
			activity[item.key].map((payload) =>
				payload.id === id
					? {
							id,
							text,
					  }
					: payload
			)
		);
	};

	const status = activity?.status || "Geen status";

	const editVisibility = () => {
		updateActivity(activity.id, "visibility", !activity.visibility);
	};

	return (
		<>
			<Navigation />
			{!activity && <Loading />}
			{activity && (
				<div className="flex grid lg:grid-cols-5 lg:gap-10">
					<div className="col-span-3 pb-6">
						<div className="mb-6 mt-2">
							<div className="flex items-center font-headings tracking-tight text-xl-tight 2xl:2xl-tight font-medium text-sm">
								<Label
									text={status || statuses[0]}
									color={`
									${!activity?.status && "bg-gray-200"}
									${status === statuses[0] && "bg-yellow-400"} 
									${status === statuses[1] && "bg-green-400"}
									${status === statuses[2] && "bg-orange-400"}
									${status === statuses[3] && "bg-blue-400"}`}
								/>
								<span className="ml-2">Naam activiteit:</span>
							</div>

							<h1 className="text-4xl max-w-xl flex items-center relative">
								{activity.alarm && <Ping />}
								{activity.name}
							</h1>
						</div>
						<Meta {...activity} />
						<Relations {...activity} />
						<Details {...activity} />
						<h1 className="text-2xl mb-2">Punten uit notitie</h1>
						<p className="text-sm font-light loading-snug text-gray-500 mb-2">
							Hieronder staan de belangrijkste punten uit de log.
						</p>

						<List
							item={points[0]}
							list={activity.success || []}
							removeItem={removeItem}
							addItem={addItem}
							editItem={editItem}
							canEdit
						/>

						<List
							item={points[1]}
							list={activity.attention || []}
							removeItem={removeItem}
							addItem={addItem}
							editItem={editItem}
							canEdit
						/>

						<List
							item={points[2]}
							list={activity.obstacles || []}
							removeItem={removeItem}
							addItem={addItem}
							editItem={editItem}
							canEdit
						/>
						<>
							<h1 className="text-2xl mb-2 pt-6">Archiveren</h1>
							{activity.visibility ? (
								<div className="flex relative rounded-lg p-2 flex items-center bg-red-100 text-red-500 max-w-lg">
									<div className="flex items-center justify-center rounded-lg w-10 h-10 bg-red-200">
										<ExclamationTriangleIcon
											className="h-5 w-5 stroke-2"
											aria-hidden="true"
										/>
									</div>
									<div className="ml-2 md:ml-4 w-full flex justify-between items-center">
										<p className="text-sm">
											Wil je deze activiteit archiveren
										</p>
										<button
											type="button"
											className="relative flex items-center bg-red-200 text-red-400 rounded-md px-3 h-9 text-sm font-medium"
											onClick={editVisibility}
										>
											Archiveren
										</button>
									</div>
								</div>
							) : (
								<div className="flex relative rounded-lg p-2 flex items-center bg-green-100 text-green-600 max-w-lg">
									<div className="flex items-center justify-center rounded-lg w-10 h-10 bg-green-200">
										<ExclamationTriangleIcon
											className="h-5 w-5 stroke-2"
											aria-hidden="true"
										/>
									</div>
									<div className="ml-2 md:ml-4 w-full flex justify-between items-center">
										<p className="text-sm">
											Zichtbaarheid herstellen
										</p>
										<button
											type="button"
											className="relative flex items-center bg-green-200 text-green-500 rounded-md px-3 h-9 text-sm font-medium"
											onClick={editVisibility}
										>
											Herstellen
										</button>
									</div>
								</div>
							)}
						</>
					</div>
					<div className="col-span-2">
						<Notes activity={activity} />
					</div>
				</div>
			)}
		</>
	);
};

export default EditActivity;
