import React from "react";
import ReactDOM from "react-dom/client";

// Styles
import "./styles/index.scss";

// Core
import { checkUserAuthentication } from "core/authentication";

// Components
import App from "./app";

const root = ReactDOM.createRoot(document.getElementById("root"));

checkUserAuthentication().then(() =>
    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    )
);
