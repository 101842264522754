import { useState } from "react";

// Core
import { toggleLocation, toggleResident } from "core/activity/actions";

// Icons
import { LinkIcon } from "@heroicons/react/24/outline";

// Components
import Button from "components/button/relation";
import FormLocation from "components/forms/form-add-location";
import FormResident from "components/forms/form-add-resident";
import FormTitle from "components/text/form-title";

const Relations = ({
	id,
	residents = {},
	locations = {},
	locationSize = 0,
	residentSize = 0,
}) => {
	const [openResident, setResident] = useState(false);
	const [openLocation, setLocation] = useState(false);

	const onOpenLocation = () => {
		setLocation(true);
	};

	const onCloseLocation = () => {
		setLocation(false);
	};

	const onOpenResident = () => {
		setResident(true);
	};

	const onCloseResident = () => {
		setResident(false);
	};

	return (
		<div className="pt-6 mt-6 border-t">
			<FormTitle
				Icon={LinkIcon}
				title="Maak een verbinding"
				description="Koppel de activiteit hier aan bewoners en/of locatie(s)."
			/>
			<div className="grid grid-cols-2 gap-4 pt-4">
				<Button
					title="Bewoner"
					description="Selecteer een bewoner of maak een nieuwe bewoner aan"
					size={residentSize}
					onClick={onOpenResident}
				/>
				<Button
					title="Locatie"
					description=" Selecteer een locatie of maak een nieuwe locatie aan"
					onClick={onOpenLocation}
					size={locationSize}
				/>
			</div>
			<FormLocation
				open={openLocation}
				onClose={onCloseLocation}
				selected={locations}
				editId={id}
				toggleLocation={toggleLocation}
			/>
			<FormResident
				open={openResident}
				onClose={onCloseResident}
				selected={residents}
				editId={id}
				toggleResident={toggleResident}
			/>
		</div>
	);
};

export default Relations;
