const Paragraph = ({
    className = "",
    text = "",
    size = "text-sm 2xl:text-md",
}) => {
    return (
        <p
            className={`font-copy font-light  leading-snug ${size} ${className}`}
        >
            {text}
        </p>
    );
};

export default Paragraph;
