import { Outlet } from "react-router-dom";

const Root = () => {
    return (
        <div className="w-screen h-screen flex items-center justify-center bg-navigation">
            <Outlet />
        </div>
    );
};

export default Root;
