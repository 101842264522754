import { useState, useEffect } from "react";

// Core
import {
	collection,
	query,
	onSnapshot,
	orderBy,
	where,
} from "firebase/firestore";
import { db } from "core/firebase";
import { unwrapList } from "core/utils";

// Components
import Navigation from "components/navigation/logbook";
import Loading from "components/list/loading";
import ListEmpty from "components/list/logbook/empty";
import List from "components/list/logbook/list";

const LogBook = () => {
	const [list, setList] = useState(null);
	const [searchList, setSearchList] = useState([]);
	const [search, setSearch] = useState("");
	const [users, setUsers] = useState([]);
	const [selected, setSelected] = useState(null);

	useEffect(() => {
		const q = query(
			collection(db, "logs"),
			where("visibility", "==", true),
			orderBy("createdAt", "desc")
		);

		const unsubscribe = onSnapshot(q, (payload) => {
			const list = unwrapList(payload);
			const users = list.reduce((accumulator, currentValue) => {
				if (
					accumulator.find(
						(item) => item?.id === currentValue?.createdBy?.id
					)
				) {
					return [...accumulator];
				} else {
					return [...accumulator, currentValue.createdBy];
				}
			}, []);

			setUsers(users);
			setList(list);
		});

		return () => unsubscribe();
	}, []);

	useEffect(() => {
		if (!list) return;

		if (!search || !search.length) {
			setSearchList(list);
		} else {
			const result = list.filter(({ title = "" }) =>
				title.toLowerCase().includes(search.toLowerCase())
			);
			setSearchList(result);
		}
	}, [search, list]);

	useEffect(() => {
		if (!list) return;

		if (!selected) {
			setSearchList(list);
		} else {
			const result = list.filter(
				({ createdBy }) => createdBy?.id === selected.id
			);
			setSearchList(result);
		}
	}, [selected, list]);

	return (
		<>
			<Navigation
				search={search}
				setSearch={setSearch}
				users={users}
				selected={selected}
				setSelected={setSelected}
			/>
			{list === null && <Loading />}
			{list && searchList?.length === 0 && <ListEmpty />}
			{searchList?.length > 0 && <List list={searchList} />}
		</>
	);
};

export default LogBook;
