import { useState } from "react";

// Components

import Sidebar from "components/sidebar";

const Root = () => {
    const [openSidebar, setSidebar] = useState(false);

    return (
        <>
            <Sidebar openSidebar={openSidebar} setSidebar={setSidebar} />
            <main className="md:pl-64 min-h-screen">
                <div className="p-2 md:p-6 flex justify-center items-center h-full">
                    <div className="text-2xl">Pagina niet gevonden</div>
                </div>
            </main>
        </>
    );
};

export default Root;
