import { useState, useEffect } from "react";

// Core
import {
	collection,
	query,
	onSnapshot,
	orderBy,
	// where,
} from "firebase/firestore";

import { db } from "core/firebase";
import { unwrapList } from "core/utils";

// Store
import { userStore } from "core/user";

// Icons
import { RectangleStackIcon } from "@heroicons/react/24/outline";

// Components
import StartLog from "components/notes/start";
import Form from "./form";
import FormTitle from "components/text/form-title";
import List from "components/notes/residents/list";

const Notes = ({ location }) => {
	const { user } = userStore();

	const [list, setList] = useState(null);

	useEffect(() => {
		const q = query(
			collection(db, "locations", location.id, "notes"),
			orderBy("createdAt", "desc")
		);

		const unsubscribe = onSnapshot(q, (payload) =>
			setList(unwrapList(payload))
		);

		return () => unsubscribe();
		// eslint-disable-next-line
	}, []);

	return (
		<div className="pl-4 border-l min-h-screen">
			<FormTitle
				Icon={RectangleStackIcon}
				title="Notities tijdlijn"
				description="Type tijdens het gesprek of vul de notitie achteraf in.
                        Notities blijven altijd aanpasbaar. Elke notitie komt op
                        de tijdlijn terecht."
			/>

			<div className="pt-6">
				<Form user={user} id={location.id} />
				{list && <List list={list} />}
				<StartLog
					type="Locatie"
					createdAt={location.createdAt}
					firstName={location?.createdBy?.firstName}
				/>
			</div>
		</div>
	);
};

export default Notes;
