import { useState, useEffect, useRef } from "react";

// Libraries
import { useParams } from "react-router-dom";
import { v4 } from "uuid";

// Core
import { doc, updateDoc, onSnapshot } from "firebase/firestore";
import { db } from "core/firebase";
import { unwrapSnapshot } from "core/utils";
import { userStore } from "core/user";
import { notificationStore } from "core/notification/store";

// Config
import { points } from "../logbook-create/config";

// Icons
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

// Components
import Navigation from "components/navigation/logbook-edit";
import Loading from "components/list/loading";
import User from "components/utils/user";
import Title from "./title";
import List from "./list";
import Description from "./description";
import Labels from "components/labels/labels-edit";
import DatePicker from "./date";

import { dateShort } from "components/utils/dates";

const EditLog = () => {
	const { openNotification } = notificationStore();
	const { id } = useParams();
	const [log, setLog] = useState(null);
	const { user } = userStore();

	const ref = useRef(doc(db, "logs", id));
	const canEdit = user.id === log?.createdBy?.id;

	useEffect(() => {
		const unsubscribe = onSnapshot(ref.current, (payload) =>
			setLog(unwrapSnapshot(payload))
		);

		return () => unsubscribe();
	}, [id]);

	const editTitle = async (payload) => {
		await updateDoc(ref.current, {
			updatedAt: new Date(),
			title: payload,
		});
		openNotification({ error: false, message: "Titel aangepast" });
	};

	const editDescription = async (payload) => {
		await updateDoc(ref.current, {
			updatedAt: new Date(),
			description: payload,
		});
	};

	const editDate = async (payload) => {
		await updateDoc(ref.current, {
			updatedAt: new Date(),
			date: payload,
		});
	};

	const editVisibility = async (payload) => {
		await updateDoc(ref.current, {
			updatedAt: new Date(),
			visibility: payload,
		});
	};

	const addItem = async ({ key, text }) => {
		await updateDoc(ref.current, {
			updatedAt: new Date(),
			[key]: log[key]
				? [{ text, id: v4() }, ...log[key]]
				: [{ text, id: v4() }],
		});
	};

	const removeItem = async ({ item, id }) => {
		await updateDoc(ref.current, {
			updatedAt: new Date(),
			[item.key]: log[item.key].filter((payload) => payload.id !== id),
		});
	};

	const editItem = async ({ item, id, text }) => {
		await updateDoc(ref.current, {
			updatedAt: new Date(),
			[item.key]: log[item.key].map((payload) =>
				payload.id === id
					? {
							id,
							text,
					  }
					: payload
			),
		});
	};

	const editLabels = async (payload) => {
		await updateDoc(ref.current, {
			updatedAt: new Date(),
			labels: payload,
		});
	};

	return (
		<>
			<Navigation />
			{!log && <Loading />}
			{log && (
				<div className="p-6 rounded-md border-2 mb-6">
					<div className="grid grid-cols-2 lg:grid-cols-3">
						<div>
							<div className="text-xs font-bold text-gray-900 mb-1">
								Aangemaakt door:
							</div>
							<User user={log.createdBy} />
						</div>
						<div>
							<div className="text-xs font-bold text-gray-900 mb-1">
								Aangemaakt op:
							</div>
							<div className="text-xs">
								{dateShort(log.createdAt)}
							</div>
						</div>

						<div>
							<div className="text-xs font-bold text-gray-900 mb-1">
								Laatst aangepast:
							</div>
							<div className="text-xs">
								{log.updatedAt
									? dateShort(log.updatedAt)
									: "Nog niet aangepast"}
							</div>
						</div>
					</div>
					<div className="max-w-xl">
						<Title
							title={log.title}
							editTitle={editTitle}
							canEdit={canEdit}
						/>
						<Labels
							canEdit={canEdit}
							selected={log.labels}
							setSelected={editLabels}
						/>
						<DatePicker
							canEdit={canEdit}
							date={log.date}
							setDate={editDate}
						/>
					</div>

					<div className="">
						<h1 className="text-2xl mb-2">Uitleg</h1>
						<p className="text-sm font-light loading-snug text-gray-900 max-w-lg">
							Hieronder vind je alle informatie die bij deze log
							hoort. Deze informatie kan alleen worden bewerkt
							door de persoon die de log heeft aangemaakt.
						</p>
					</div>
					<div className="grid lg:grid-cols-2 lg:gap-10 pt-6">
						<div>
							<h1 className="text-2xl mb-2">Notitie</h1>
							<Description
								description={log.description}
								editDescription={editDescription}
								canEdit={canEdit}
							/>
						</div>
						<div>
							<h1 className="text-2xl mb-2">
								Punten uit notitie
							</h1>
							<p className="text-sm font-light loading-snug text-gray-500 mb-2">
								Hieronder staan de belangrijkste punten uit de
								log.
							</p>

							<List
								item={points[0]}
								list={log.success || []}
								removeItem={removeItem}
								addItem={addItem}
								editItem={editItem}
								canEdit={canEdit}
							/>

							<List
								item={points[1]}
								list={log.attention || []}
								removeItem={removeItem}
								addItem={addItem}
								editItem={editItem}
								canEdit={canEdit}
							/>

							<List
								item={points[2]}
								list={log.obstacles || []}
								removeItem={removeItem}
								addItem={addItem}
								editItem={editItem}
								canEdit={canEdit}
							/>
						</div>
					</div>
					{canEdit && (
						<>
							<h1 className="text-2xl mb-2 pt-6">Archiveren</h1>
							{log.visibility ? (
								<div className="flex relative rounded-lg p-2 flex items-center bg-red-100 text-red-500 max-w-lg">
									<div className="flex items-center justify-center rounded-lg w-10 h-10 bg-red-200">
										<ExclamationTriangleIcon
											className="h-5 w-5 stroke-2"
											aria-hidden="true"
										/>
									</div>
									<div className="ml-2 md:ml-4 w-full flex justify-between items-center">
										<p className="text-sm">
											Wil je dit log archiveren
										</p>
										<button
											type="button"
											className="relative flex items-center bg-red-200 text-red-400 rounded-md px-3 h-9 text-sm font-medium"
											onClick={() =>
												editVisibility(!log.visibility)
											}
										>
											Archiveren
										</button>
									</div>
								</div>
							) : (
								<div className="flex relative rounded-lg p-2 flex items-center bg-green-100 text-green-600 max-w-lg">
									<div className="flex items-center justify-center rounded-lg w-10 h-10 bg-green-200">
										<ExclamationTriangleIcon
											className="h-5 w-5 stroke-2"
											aria-hidden="true"
										/>
									</div>
									<div className="ml-2 md:ml-4 w-full flex justify-between items-center">
										<p className="text-sm">
											Zichtbaarheid herstellen
										</p>
										<button
											type="button"
											className="relative flex items-center bg-green-200 text-green-500 rounded-md px-3 h-9 text-sm font-medium"
											onClick={() =>
												editVisibility(!log.visibility)
											}
										>
											Herstellen
										</button>
									</div>
								</div>
							)}
						</>
					)}
				</div>
			)}
		</>
	);
};

export default EditLog;
