import { useState, useEffect } from "react";

// Core
import { collection, query, onSnapshot } from "firebase/firestore";
import { db } from "core/firebase";
import { unwrapList } from "core/utils";

// Components
import ListEmpty from "components/list/empty";
import ListLoading from "components/list/loading";
// import List from "components/list/list";
import Admins from "components/admins/admins";

const Search = () => {
    const [list, setList] = useState(null);

    useEffect(() => {
        const q = query(collection(db, "admins"));

        const unsubscribe = onSnapshot(q, (payload) =>
            setList(unwrapList(payload))
        );

        return () => unsubscribe();
    }, []);

    if (!list) {
        return <ListLoading />;
    }

    if (!list.length) {
        return <ListEmpty />;
    }

    return <Admins list={list} />;
};

export default Search;
