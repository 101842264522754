export const types = {
    note: "note",
    update: "update",
    list: "list",
};

export const editKeys = {
    firstName: "voornaam",
    lastName: "achternaam",
    address: "adres",
    email: "mailadres",
    mobile: "telefoonnummer",
    age: "leeftijd",
    avg: "avg",
    alarm: "alarm",
    dream: "droom",
    themes: "thema’s",
    ideas: "ideeën",
    types: "Talent/passie",
    role: "rol bewoner",
};
