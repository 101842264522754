// Icons
import { PlusCircleIcon } from "@heroicons/react/24/outline";

const Button = ({ title, description, onClick, size = 0 }) => {
    return (
        <button
            type="button"
            onClick={onClick}
            className="bg-green-200 p-6 w-full rounded-md border-2 border-dashed border-green-400"
        >
            <div className="text-left font-medium text-green-500 text-base flex">
                {title}
                <PlusCircleIcon className="h-6 w-6 stroke-2 ml-2" />
            </div>
            <p className="text-left text-sm text-green-500">
                {description}
                <span className="font-bold text-xs text-white px-2 py-1 rounded-md bg-green-400 ml-2">
                    {size}
                </span>
            </p>
        </button>
    );
};

export default Button;
