import { useState } from "react";

// Icon
import { ArrowLeftIcon } from "@heroicons/react/24/outline";

// Components
import Textarea from "components/inputs/textarea";
import Button from "components/button/border";

const Description = ({ description, editDescription, canEdit = false }) => {
	const [edit, setEdit] = useState(false);
	const [updatedDescription, setDescription] = useState(description);

	if (edit) {
		return (
			<div className="py-6 max-w-xl">
				<Textarea
					value={updatedDescription}
					onChange={({ target }) => setDescription(target.value)}
				/>
				<div className="flex items-center mt-2">
					<Button onClick={() => setEdit(false)} className="mr-4">
						<ArrowLeftIcon className="w-5 h-5 stroke-2" />
					</Button>
					<Button
						text="Opslaan"
						onClick={() => {
							editDescription(updatedDescription);
							setEdit(false);
						}}
						disabled={!updatedDescription.length}
					></Button>
				</div>
			</div>
		);
	}

	return (
		<p className="text-sm font-light loading-snug text-gray-900 mb-2 whitespace-pre-line">
			{description}{" "}
			{canEdit && (
				<button
					onClick={() => setEdit(true)}
					className="font-bold text-xs ml-2 text-green-400"
				>
					aanpassen
				</button>
			)}
		</p>
	);
};

export default Description;
