import { writeBatch, doc, collection } from "firebase/firestore";

// Db
import { db } from "core/firebase";

// Stores
import { userStore } from "core/user";

export const createLog = async (payload) => {
    const { user } = userStore.getState();

    const batch = writeBatch(db);

    const refLog = doc(collection(db, "logs"));
    const refLogRaw = doc(db, "logs_raw", refLog.id);

    delete user.canEdit;
    delete user.canCreate;
    delete user.canRead;
    delete user.canViewContactInfo;
    delete user.canViewUsers;

    batch.set(refLogRaw, {
        ...payload,
        createdBy: user,
        createdAt: new Date(),
    });

    delete payload.contact;

    batch.set(refLog, {
        ...payload,
        createdBy: user,
        createdAt: new Date(),
        visibility: true,
    });

    const error = await batch.commit().catch((error) => error);

    return error;
};
