// Components
import Item from "./item";

const List = ({ list = [] }) => {
	return (
		<div className="">
			<div className="grid grid-cols-6 gap-2 lg:grid-cols-10 lg:grid-gap-4 flex items-center py-4">
				<div className="col-span-1 text-xs font-bold">Log datum</div>
				<div className="col-span-4 text-xs font-bold">Titel</div>
				<div className="text-xs font-bold hidden lg:inline-flex md:col-span-2">
					Gemaakt door
				</div>
				<div className="text-xs font-bold hidden lg:inline-flex lg:col-span-2">
					Punten uit notitie
				</div>
				<div className="text-xs font-bold hidden lg:inline-flex lg:col-span-1">
					<span className="px-2 py-[2px] rounded-lg inline-flex bg-gray-100 text-gray-500">
						Totaal: {list.length}
					</span>
				</div>
			</div>
			{list.map((item) => (
				<Item key={item.id} {...item} />
			))}
		</div>
	);
};

export default List;
