import { useState } from "react";

// Icon
import {
    PencilSquareIcon,
    ArrowLeftCircleIcon,
} from "@heroicons/react/24/outline";

// Components
import Label from "components/labels/label";
import Button from "components/button/border";

const EditCategories = ({
    label,
    selected = [],
    list = [],
    editKey,
    editFunction,
    id,
}) => {
    const [edit, setEdit] = useState(false);
    const [updatedList, setUpdatedList] = useState(selected);

    const openEdit = () => {
        setEdit(true);
    };

    const closeEdit = () => {
        setEdit(false);
    };

    const toggleLabel = (payload) => {
        const isInList = updatedList.some((item) => item === payload);

        if (isInList) {
            setUpdatedList(updatedList.filter((item) => item !== payload));
        } else {
            setUpdatedList([...updatedList, payload]);
        }
    };

    const editItem = async () => {
        await editFunction(id, editKey, updatedList);
        closeEdit();
    };

    if (edit) {
        return (
            <div>
                <div className="text-sm font-semibold mt-2">{label}</div>
                {list.map((item) => (
                    <button
                        type="button"
                        key={item}
                        onClick={() => toggleLabel(item)}
                    >
                        <Label
                            text={item}
                            active={updatedList.some(
                                (payload) => payload === item
                            )}
                        />
                    </button>
                ))}
                <div className="flex items-center mt-4">
                    <Button onClick={closeEdit} className="mr-4">
                        <ArrowLeftCircleIcon className="w-5 h-5 stroke-2" />
                    </Button>
                    <Button
                        text="Opslaan"
                        color="bg-green-400 text-white"
                        onClick={editItem}
                    />
                </div>
            </div>
        );
    }

    return (
        <div className="grid grid-cols-6 flex items-center py-2">
            <div className="col-span-2">
                <div className="text-sm font-semibold">{label}</div>
            </div>
            <div className="col-span-4 flex justify-between">
                {!selected.length && (
                    <span className="text-sm text-gray-400 font-light">
                        Nog geen items
                    </span>
                )}
                <div>
                    {selected.map((item) => (
                        <Label key={item} text={item} />
                    ))}
                </div>
                <button className="p-2" type="button" onClick={openEdit}>
                    <PencilSquareIcon className="h-5 w-5 stroke-2 text-gray-600" />
                </button>
            </div>
        </div>
    );
};

export default EditCategories;
