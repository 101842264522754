import { useState } from "react";

// Core
import { toggleActivity, toggleResident } from "core/location/actions";

// Icons
import { LinkIcon } from "@heroicons/react/24/outline";

// Components
import Button from "components/button/relation";
import FormActivity from "components/forms/form-add-activity";
import FormResident from "components/forms/form-add-resident";
import FormTitle from "components/text/form-title";

const Relations = ({
	id,
	residents = {},
	activities = {},
	activitySize = 0,
	residentSize = 0,
}) => {
	const [openResident, setResident] = useState(false);
	const [openActivity, setActivity] = useState(false);

	const onOpenActivity = () => {
		setActivity(true);
	};

	const onCloseActivity = () => {
		setActivity(false);
	};

	const onOpenResident = () => {
		setResident(true);
	};

	const onCloseResident = () => {
		setResident(false);
	};

	return (
		<div className="pt-6 mt-6 border-t">
			<FormTitle
				Icon={LinkIcon}
				title="Maak een verbinding"
				description="Koppel de locatie hier aan bewoners en/of activiteiten."
			/>
			<div className="grid grid-cols-2 gap-4 pt-4">
				<Button
					title="Bewoner"
					description="Voeg een bewoner toe"
					size={residentSize}
					onClick={onOpenResident}
				/>
				<Button
					title="Activiteit"
					description="Voeg een activiteit toe"
					onClick={onOpenActivity}
					size={activitySize}
				/>
			</div>
			<FormActivity
				open={openActivity}
				onClose={onCloseActivity}
				selected={activities}
				editId={id}
				toggleActivity={toggleActivity}
			/>
			<FormResident
				open={openResident}
				onClose={onCloseResident}
				selected={residents}
				editId={id}
				toggleResident={toggleResident}
			/>
		</div>
	);
};

export default Relations;
