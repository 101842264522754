import { useState } from "react";

// Libraries
import { v4 } from "uuid";

// Icon
import {
    ArrowLeftCircleIcon,
    PlusCircleIcon,
    PencilSquareIcon,
    TrashIcon,
} from "@heroicons/react/24/outline";

// Components
import Input from "components/inputs/input";
import Button from "components/button/border";

const Item = ({ id, text = "", editItem, removeItem }) => {
    const [edit, setEdit] = useState(false);
    const [updatedText, setText] = useState(text);

    if (edit) {
        return (
            <>
                <div className="flex items-center mb-2 mt-2">
                    <Input
                        placeholder="Type een omschrijving"
                        value={updatedText}
                        onChange={({ target }) => setText(target.value)}
                    />
                </div>
                <div className="flex items-center">
                    <Button onClick={() => setEdit(false)} className="mr-4">
                        <ArrowLeftCircleIcon className="w-5 h-5 stroke-2" />
                    </Button>
                    <Button
                        text="Opslaan"
                        color="bg-green-400 text-green-700"
                        onClick={() => {
                            editItem(id, updatedText);
                            setEdit(false);
                        }}
                        disabled={!text?.length}
                    ></Button>
                    <Button
                        onClick={() => removeItem(id)}
                        className="bg-red-400 text-red-700 ml-4"
                    >
                        <TrashIcon className="w-5 h-5 stroke-2" />
                    </Button>
                </div>
            </>
        );
    }
    return (
        <div className="flex items-center justify-between">
            <p className="text-sm font-light loading-snug text-gray-500">
                {text}{" "}
            </p>

            <button type="button" className="p-2" onClick={() => setEdit(true)}>
                <PencilSquareIcon className="inline-block h-5 w-5 stroke-2 text-gray-600" />
            </button>
        </div>
    );
};

const EditList = ({ label, list = [], id, editKey, editFunction }) => {
    const [edit, setEdit] = useState(false);
    const [text, setText] = useState("");

    const openEdit = () => {
        setEdit(true);
    };

    const addItem = async () => {
        await editFunction(id, editKey, [{ text, id: v4() }, ...list]);

        setText("");
    };

    const removeItem = async (selected) => {
        await editFunction(
            id,
            editKey,
            list.filter((item) => item.id !== selected)
        );
    };

    const editItem = async (selected, payload) => {
        await editFunction(
            id,
            editKey,
            list.map((item) =>
                item.id === selected
                    ? {
                          ...item,
                          text: payload,
                      }
                    : item
            )
        );
    };

    return (
        <>
            {edit ? (
                <>
                    <div className="text-sm font-semibold mb-2">{label}</div>
                    <Input
                        placeholder="Type een omschrijving"
                        value={text}
                        onChange={({ target }) => setText(target.value)}
                    />

                    <div className="flex items-center mt-4">
                        <Button onClick={() => setEdit(false)} className="mr-4">
                            <ArrowLeftCircleIcon className="w-5 h-5 stroke-2" />
                        </Button>
                        <Button
                            text="Opslaan"
                            color="bg-green-400 text-green-700"
                            onClick={() => {
                                addItem();
                                setEdit(false);
                            }}
                        ></Button>
                    </div>
                </>
            ) : (
                <div className="grid grid-cols-6 flex items-center mt-2">
                    <div className="col-span-2">
                        <div className="text-sm font-semibold">{label}</div>
                    </div>
                    <div className="text-sm col-span-4 font-light flex justify-between items-center">
                        <span className="text-gray-400">
                            Voeg een item aan de lijst toe
                        </span>

                        <button
                            className="p-2"
                            type="button"
                            onClick={openEdit}
                        >
                            <PlusCircleIcon className="h-5 w-5 stroke-2 text-gray-600" />
                        </button>
                    </div>
                </div>
            )}
            <div className="grid grid-cols-6 flex items-center">
                <div className="col-span-2"></div>
                <div className="text-sm col-span-4 font-light">
                    {list.map((item) => (
                        <Item
                            key={item.id}
                            {...item}
                            editItem={editItem}
                            removeItem={removeItem}
                        />
                    ))}
                </div>
            </div>
        </>
    );
};

export default EditList;
