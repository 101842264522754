// Core
import { updateResident } from "core/resident/actions";

// Config
import { rolesList, themesList, agesList } from "./config";

// Icons
import {
    UserCircleIcon,
    GlobeEuropeAfricaIcon,
} from "@heroicons/react/24/outline";

// Components
import FormTitle from "components/text/form-title";
import EditAddress from "components/edit/address";
import EditText from "components/edit/text";
import EditList from "components/edit/list";
import EditCategories from "components/edit/categories";
import EditSelect from "components/edit/select";
import EditSwitch from "components/edit/switch";

const Details = ({
    id = "",
    firstName = "",
    lastName = "",
    address = "",
    email = "",
    number = "",
    age = "",
    avg = false,
    alarm = false,
    dream = "",
    themes = [],
    ideas = [],
    types = [],
    role = "",
}) => {
    return (
        <>
            <div className="pt-6 mt-6 border-t" />
            <FormTitle
                Icon={UserCircleIcon}
                title="Informatie bewoner"
                description="Basis gegevens van de bewoner."
            />
            <EditText
                label="Voornaam:"
                editKey="firstName"
                editFunction={updateResident}
                value={firstName}
                id={id}
            />
            <EditText
                label="Achternaam:"
                editKey="lastName"
                editFunction={updateResident}
                value={lastName}
                id={id}
            />
            <EditAddress
                label="Adres:"
                editKey="address"
                editFunction={updateResident}
                value={address}
                id={id}
            />

            <EditText
                label="Mailadres:"
                editKey="email"
                editFunction={updateResident}
                value={email}
                id={id}
            />
            <EditText
                label="Telefoonnummer:"
                editKey="number"
                editFunction={updateResident}
                value={number}
                id={id}
            />
            <EditSelect
                label="Leeftijd:"
                editKey="age"
                editFunction={updateResident}
                value={age}
                list={agesList}
                id={id}
            />
            <EditSwitch
                label="AVG check:"
                editKey="avg"
                editFunction={updateResident}
                labelOn="AVG staat aan"
                labelOff="AVG staat uit"
                value={avg}
                id={id}
            />
            <EditSwitch
                label="Alarm:"
                editKey="alarm"
                editFunction={updateResident}
                labelOn="Alarm staat aan"
                labelOff="Alarm staat uit"
                value={alarm}
                id={id}
            />
            <div className="pt-6 mt-6 border-t" />
            <FormTitle
                Icon={GlobeEuropeAfricaIcon}
                title="Persoonlijke informatie"
                description=" Vul hieronder persoonlijke informatie in."
            />
            <EditText
                label="De droom:"
                editKey="dream"
                editFunction={updateResident}
                value={dream}
                id={id}
            />
            <EditCategories
                label="Thema’s:"
                editKey="themes"
                editFunction={updateResident}
                list={themesList}
                selected={themes}
                id={id}
            />
            <EditList
                label="Ideeën:"
                editKey="ideas"
                editFunction={updateResident}
                list={ideas}
                id={id}
            />
            <EditList
                label="Talent / passie / kennis / kunde:"
                editKey="types"
                editFunction={updateResident}
                list={types}
                id={id}
            />
            <EditSelect
                label="Rol bewoner:"
                editKey="role"
                editFunction={updateResident}
                value={role}
                id={id}
                list={rolesList}
            />
        </>
    );
};

export default Details;
