import { useState } from "react";

// Icon
import { ArrowLeftIcon } from "@heroicons/react/24/outline";

// Components
import Input from "components/inputs/input";
import Button from "components/button/border";

const Title = ({ title, editTitle, canEdit = false }) => {
    const [edit, setEdit] = useState(false);
    const [updatedTitle, setTitle] = useState(title);

    if (edit) {
        return (
            <div className="py-6 max-w-xl">
                <Input
                    value={updatedTitle}
                    onChange={({ target }) => setTitle(target.value)}
                />
                <div className="flex items-center mt-2">
                    <Button onClick={() => setEdit(false)} className="mr-4">
                        <ArrowLeftIcon className="w-5 h-5 stroke-2" />
                    </Button>
                    <Button
                        text="Opslaan"
                        onClick={() => {
                            editTitle(updatedTitle);
                            setEdit(false);
                        }}
                        disabled={!updatedTitle.length}
                    ></Button>
                </div>
            </div>
        );
    }

    return (
        <div className="pt-6">
            <h1 className="text-2xl max-w-xl leading-snug">
                {title}
                {canEdit && (
                    <button
                        onClick={() => setEdit(true)}
                        className="font-bold text-xs ml-2 text-green-400"
                    >
                        aanpassen
                    </button>
                )}
            </h1>
        </div>
    );
};

export default Title;
