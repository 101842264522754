// Components
const Tab = ({ color, text, onClick, active }) => {
	return (
		<button
			onClick={onClick}
			type="button"
			className={`font-semibold text-sm h-11 px-4 border-2 rounded-md ${
				active ? color : "border-transparent"
			}`}
		>
			{text}
		</button>
	);
};

const Navigation = ({
	toggleActivities,
	toggleResidents,
	toggleLocations,
	activeLocations,
	activeResidents,
	activeActivities,
}) => {
	return (
		<div className="h-16 flex items-center justify-between">
			<h1 className="text-xl text-gray-900 ml-4">Map</h1>
			<div className="flex items-center gap-4">
				<Tab
					onClick={toggleActivities}
					color={"border-[#706fd3] text-[#706fd3]"}
					text="Activiteiten"
					active={activeActivities}
				></Tab>
				<Tab
					onClick={toggleResidents}
					active={activeResidents}
					color={"border-[#34ACE0] text-[#34ACE0]"}
					text="Bewoners"
				></Tab>
				<Tab
					onClick={toggleLocations}
					active={activeLocations}
					color={"border-[#33D9B2] text-[#33D9B2]"}
					text="Locaties"
				></Tab>
			</div>
		</div>
	);
};

export default Navigation;
