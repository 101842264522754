import { ArrowLeftCircleIcon } from "@heroicons/react/24/outline";

// Components
import Button from "components/button/link";

const Navigation = () => {
	return (
		<div className="h-16 flex items-center">
			<Button href="/locaties" size="h-10">
				<ArrowLeftCircleIcon className="h-5 w-5 stroke-2 mr-2" />
				<span>Terug</span>
			</Button>
			<h1 className="text-xl text-gray-900 ml-4">Locatie detail</h1>
		</div>
	);
};

export default Navigation;
