import { useState, useCallback } from "react";

// Libraries
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";

const places = ["places"];

const AutoCompleteInput = ({ onChange = () => {}, value = "" }) => {
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
        libraries: places,
    });

    const [map, setMap] = useState(null);

    const onLoad = useCallback(function callback(mapsInstance) {
        setMap(mapsInstance);
    }, []);

    const onUnmount = useCallback(function callback() {
        setMap(null);
    }, []);

    const onPlaceChanged = () => {
        const location = map.getPlace();
        onChange({
            address: location.formatted_address,
            lat: location.geometry.location.lat(),
            lng: location.geometry.location.lng(),
        });
    };

    if (!isLoaded) {
        return (
            <input
                type="text"
                disabled
                placeholder="Autocomplete word geladen"
                className="h-10 block w-full rounded-md border-2 border-gray-200 bg-gray-100 sm:text-sm focus:outline-none focus:ring-transparent placeholder:text-gray-300"
            />
        );
    }

    return (
        <Autocomplete
            onLoad={onLoad}
            onUnmount={onUnmount}
            onPlaceChanged={onPlaceChanged}
            restrictions={{ country: "nl" }}
        >
            <input
                type="text"
                placeholder="Type hier een adres"
                className={`h-10 block w-full rounded-md border-2 border-gray-200 focus:border-green-400 sm:text-sm focus:outline-none focus:ring-transparent placeholder:text-gray-400`}
            />
        </Autocomplete>
    );
};

export default AutoCompleteInput;
